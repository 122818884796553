import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GridColumnDescriptor } from '../../models/grid/grid-column-descriptor';

@Component({
  selector: 'app-generic-grid-fields',
  templateUrl: './generic-grid-fields.component.html',
  styleUrls: ['./generic-grid-fields.component.scss']
})
export class GenericGridFieldsComponent {

  @Input() columnIndex: number;
  @Input() column: GridColumnDescriptor;
  @Input() line: any;
  @Input() errors: {[key: string]: string} = {};

  @Output() lineChange = new EventEmitter<any>();

  public selectedChips: any;
  constructor() {}

  onFieldChange() {
    this.lineChange.emit(this.line);
  }

  globalKeyPressTextOnly(event: any) {
    let newValue = event.key;
    const oldValue = event.currentTarget.value;
    if (event.currentTarget) {
      newValue = `${oldValue}${newValue}`;
    }

    let pattern = /./;
    if (this.column.type === 'number') {
      pattern = /^[0-9]*[,.]?[0-9]*$/;
    }

    if (!newValue.match(pattern)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  numberFieldChange(model: any, path: string, value: string) {
    model[path] = value.replace(/,/g, '.');
    this.onFieldChange();
  }

  keyPressTextOnly(event: any) {
    let pattern = /./;
    if (this.column.type === 'text-only') {
      pattern = /[a-zA-Z\s]/;
    } else if (this.column.type === 'number-only') {
      pattern = /^[0-9]+$/;
    }
    const inputChar = String.fromCharCode(event.keyCode);

    if (!inputChar.match(pattern)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  onSelectChange(value, column) {
    this.line[column.value] = value;
    this.onFieldChange();
  }

  onChipsSelectChange(value) {
    this.selectedChips = value;
  }

  onChipsSelectClosed() {
    if (this.column.selectDescriptor && this.column.selectDescriptor.selectClosedBehavior) {
      this.column.selectDescriptor.selectClosedBehavior();
    }
  }

  addChips(chips: any) {
    if (chips && this.line && this.column && Array.isArray(this.line[this.column.value])) {
      if (this.column.chipsIdentifier) {
        const index = this.line[this.column.value].findIndex((c) => {
          return c[this.column.chipsIdentifier] === chips[this.column.chipsIdentifier];
        });
        if (index < 0) {
          this.line[this.column.value].push(chips);
        }
      } else {
        this.line[this.column.value].push(chips);
      }
      this.onFieldChange();
      this.selectedChips = "";
    }
  }

  removeChips(index: number) {
    if (this.line && this.column && Array.isArray(this.line[this.column.value])) {
      this.line[this.column.value].splice(index, 1);
      this.onFieldChange();
    }
  }
}
