import { Prestation } from './prestation';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Country } from './country';

export class Discount {

  constructor( public id?: number,
               public rate?: string,
               public startDate?: Moment,
               public endDate?: Moment,
               public prestation?: Prestation,
               public country?: Country
  ) {}

  static mapToDiscount(data: any) {
    let prestation: Prestation;
    if (data.product) {
      prestation = Prestation.mapToPrestation(data.product);
    }

    // let rate: string;
    // if (data.rate) {
    //   rate = `${data.rate}%`;
    // }

    let start;
    if (data.startDate) {
      start = moment(data.startDate);
    }

    let end;
    if (data.endDate) {
      end = moment(data.endDate);
    }

    return new Discount(
      data.id,
      data.rate,
      start,
      end,
      prestation,
      data.country
    );
  }
}
