import { FieldValidator } from './field-validator';
import { GridSelectDescriptor } from './grid-select-descriptor';

export class GridColumnDescriptor {

  constructor( public id: number,
               public label: string,
               public labelParameters: any,
               public value: string,
               public type: string,
               public subType?: string,
               public display?: string,
               public displayParams?: Array<{key: string, value: string}>,
               public suffix?: string,
               public required?: boolean,
               public disabled?: boolean,
               public fieldValidator?: FieldValidator,
               public weight?: string,
               public selectDescriptor?: GridSelectDescriptor,
               public chipsIdentifier?: string
  ) {}
}
