import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  public message: string;
  public status: string;

  constructor() {}

  setToast(message: string, status: string) {
    this.message = message;
    this.status = status;
    setTimeout(() => {
      this.resetToast();
    }, 3000);
  }

  resetToast() {
    this.message = '';
    this.status = '';
  }
}
