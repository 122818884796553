import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from '../core/services/login.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  public state;

  constructor(private loginService: LoginService,
              private cookieService: CookieService,
              private route: ActivatedRoute) {}

  ngOnInit(): void {
    const url = this.route.snapshot.url;
    if (url && url[url.length - 1]) {
      this.state = url[url.length - 1].path;

      if (this.state === 'landing') {
        this.loginService.getToken().subscribe(
          (response) => {
              const token = response.headers.get('authorization');
              if (token) {
                this.loginService.login(token);
              }
            }
          );
      }
    } else {
      this.state = 'error';
    }
  }
}
