import {Component, OnDestroy, OnInit} from '@angular/core';
import {GridDescriptor} from '../../core/models/grid/grid-descriptor';
import {Operation, OPTYPES} from '../../core/models/operation';
import {OperationsService} from './services/operations.service';
import {Observable} from 'rxjs';
import {ModalDescriptor} from '../../core/models/modal/modal-descriptor';
import {GenartService} from '../../core/services/genart.service';
import {Genart} from '../../core/models/genart';
import {I18nService} from 'src/app/core/services/i18n.service';

@Component({
  selector: 'app-operations-grid',
  templateUrl: './operations-grid.component.html',
  styleUrls: ['./operations-grid.component.scss']
})
export class OperationsGridComponent implements OnInit, OnDestroy {
  public descriptor: GridDescriptor;
  public isModifOK: boolean = false;

  public newLineStructure: {
    code: string,
    labelFr: string,
    labelEn: string,
    labelEs: string,
    type: { value: string, label: string },
    brrNumber: number,
    genarts: Genart[]
  } = {
    code: '',
    labelFr: '',
    labelEn: '',
    labelEs: '',
    type: undefined,
    brrNumber: undefined,
    genarts: []
  };

  public operations: Array<Operation> = [];

  private modalDescriptorModel: ModalDescriptor = {
    width: 450,
    height: 200,
    title: '',
    titleParameters: {},
    message: '',
    messageParameters: {},
    showModal: false,
    actions: [],
    clickOutsideBehavior: undefined,
    clickOutsideBehaviorParameters: []
  };

  public modalDescriptor: ModalDescriptor = JSON.parse(JSON.stringify(this.modalDescriptorModel));

  public searchText = '';
  private searchTimeout: any;

  public totalItem = 0;
  public loadingPage = false;
  private lastPage = 0;
  private lang: string;

  public genarts: Genart[] = [];
  public allGenarts: Genart[] = [];
  public showedGenarts: Genart[] = [];
  public genartPage = 0;
  public genartsSearchText: string;
  private genartsSubscription: any;

  constructor(private operationsService: OperationsService,
              private genartService: GenartService,
              private i18nService: I18nService) {
  }

  ngOnInit() {
    this.i18nService.getLang().subscribe(
      (lang: string) => {
        this.lang = lang.charAt(0).toUpperCase() + lang.substr(1).toLowerCase();
        if (this.descriptor && this.descriptor.columns && this.descriptor.columns[5] &&
          this.descriptor.columns[5].selectDescriptor && this.descriptor.columns[5].selectDescriptor.model) {
          this.descriptor.columns[5].selectDescriptor.model.name = `concatenation${this.lang}`;
        }
      }
    );
    this.genartsSubscription = this.genartService.getGenartsSubject().subscribe(
      (genarts: Genart[]) => {
        this.allGenarts = genarts;
        this.genarts = genarts;
        this.showedGenarts = genarts.slice(0, 20);
        this.genartPage = 0;
        // this.filterGenart(this.genartsSearchText);
        this.getGenartPage(true);
      }
    );
    this.getOperations(0);
  }

  ngOnDestroy() {
    this.genartsSubscription.unsubscribe();
  }

  searchChanged() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.getOperations(0);
      this.searchTimeout = undefined;
    }, 300);
  }

  getNextPage() {
    if (!this.loadingPage && this.totalItem && this.operations.length < this.totalItem) {
      this.getOperations(this.lastPage + 1);
    }
  }

  getGenartPage(reset?: boolean) {
    if (reset) {
      this.genartPage = 0;
      this.showedGenarts = this.genarts.slice(0, 20);
    } else {
      this.genartPage = this.genartPage + 1;
      const offset = this.genartPage * 20;
      this.showedGenarts = [...this.showedGenarts, ...this.genarts.slice(offset, offset + 20)];
    }
    if (this.descriptor) {
      this.descriptor.columns[5].selectDescriptor.options = this.showedGenarts;
    } else {
      this.setDescriptor();
    }
  }

  getOperations(page?: number) {
    this.loadingPage = true;
    this.operationsService.getOperations(this.searchText, page, false).subscribe(
      (result: { total: number, operations: Array<Operation> }) => {
        this.totalItem = result.total;
        this.lastPage = page;
        if (result.operations) {
          if (page === 0) {
            this.setOperations(result.operations);
          } else {
            this.setOperations([...this.operations, ...result.operations]);
          }
        }
        this.loadingPage = false;
      }
    );
  }

  setOperations(operations: Array<Operation>) {
    this.operations = operations;
  }

  setDescriptor() {
    this.descriptor = {
      columns: [
        {
          id: 1,
          label: 'APP.OPERATIONS_GRID.CODE',
          labelParameters: {},
          value: 'code',
          type: 'text',
          required: true,
          weight: '2'
        },
        {
          id: 2,
          label: 'APP.OPERATIONS_GRID.LABEL_FR',
          labelParameters: {},
          value: 'labelFr',
          type: 'text',
          required: true,
          weight: '3'
        },
        {
          id: 3,
          label: 'APP.OPERATIONS_GRID.LABEL_EN',
          labelParameters: {},
          value: 'labelEn',
          type: 'text',
          required: true,
          weight: '3'
        },
        {
          id: 4,
          label: 'APP.OPERATIONS_GRID.LABEL_ES',
          labelParameters: {},
          value: 'labelEs',
          type: 'text',
          required: true,
          weight: '3'
        },
        {
          id: 5,
          label: 'APP.OPERATIONS_GRID.BRR_NUMBER',
          labelParameters: {},
          value: 'brrNumber',
          type: 'number-only',
          required: false,
          weight: '2'
        },
        {
          id: 6,
          label: 'APP.OPERATIONS_GRID.GENART',
          labelParameters: {},
          value: 'genarts',
          type: 'selected-chips',
          required: false,
          fieldValidator: {
            isValid: this.validateOperation,
            validationParams: [],
            linkedValidation: [7],
            message: 'APP.OPERATIONS_GRID.ERRORS.GENART'
          },
          weight: '4',
          selectDescriptor: {
            options: this.showedGenarts,
            model: {name: `concatenation${this.lang}`, value: ''},
            searchChangeBehavior: this.filterGenart.bind(this),
            selectClosedBehavior: this.filterGenart.bind(this),
            pageChangeBehavior: this.getGenartPage.bind(this),
          },
          chipsIdentifier: 'code'
        },
        {
          id: 7,
          label: 'APP.OPERATIONS_GRID.OPTYPE',
          labelParameters: {},
          value: 'type',
          display: 'type.label',
          type: 'select',
          required: true,
          weight: '5',
          fieldValidator: {
            validationParams: [],
            linkedValidation: [6]
          },
          selectDescriptor: {
            options: OPTYPES,
            model: {name: 'label', value: ''}
          }
        }
      ],
      actions: [
        {
          label: 'remove',
          tooltip: 'APP.OPERATIONS_GRID.REMOVE_LINE_TOOLTIP',
          icon: 'fa-trash error-button',
          behavior: this.removeOperation.bind(this),
          confirmation: true,
          parameters: []
        }
      ],
      actionWeight: '1',
      newLine: true
    };
  }

  addLine(newLine: {
    code: string,
    labelFr: string,
    labelEn: string,
    labelEs: string,
    type: { value: string, label: string },
    brrNumber: number,
    genarts: Genart[],
    id?: number
  }) {

    if (newLine.id) {
      this.operationsService.setLine(newLine).subscribe(
        (operation: Operation) => {
          const index = this.operations.findIndex((d) => d.id === operation.id);
          if (index > -1) {
            this.operations[index] = operation;
          } else {
            this.operations.push(operation);
          }
          this.isModifOK = !this.isModifOK;
        }
      );
    } else {
      this.operationsService.addLine(newLine).subscribe(
        (newOperation: Operation) => {
          this.operations.push(newOperation);
          this.isModifOK = !this.isModifOK;
        }
      );
    }
  }

  validateOperation(property: any, line: {
    code: string,
    labelFr: string,
    labelEn: string,
    labelEs: string,
    type: { value: string, label: string },
    brrNumber: number,
    genarts: Genart[],
    id?: number
  }): boolean {

    return (line.type.value !== 'GLOBAL' && line.genarts.length > 0) || line.type.value === 'GLOBAL';
  }

  closeModal() {
    this.modalDescriptor = JSON.parse(JSON.stringify(this.modalDescriptorModel));
  }

  confirmModal(observer) {
    observer.next(true);
    observer.complete();
    this.closeModal();
  }

  cancelModal(observer) {
    observer.next(false);
    observer.complete();
    this.closeModal();
  }

  openRemoveModal(operation: Operation) {
    return new Observable((observer) => {
      this.modalDescriptor.title = 'APP.OPERATIONS_GRID.REMOVE_MODAL_TITLE';
      this.modalDescriptor.message = 'APP.OPERATIONS_GRID.REMOVE_MODAL_MESSAGE';
      this.modalDescriptor.actions.push({
        icon: '',
        label: 'APP.ACTIONS.CANCEL',
        color: 'white',
        behavior: this.cancelModal.bind(this),
        behaviorParameters: [observer]
      });
      this.modalDescriptor.actions.push({
        icon: '',
        label: 'APP.ACTIONS.CONFIRM',
        color: 'red',
        behavior: this.confirmModal.bind(this),
        behaviorParameters: [observer]
      });
      this.modalDescriptor.clickOutsideBehavior = this.cancelModal.bind(this);
      this.modalDescriptor.clickOutsideBehaviorParameters = [observer];
      this.modalDescriptor.showModal = true;
    });
  }

  removeOperation(operationId: number) {
    const index = this.operations.findIndex((operation) => operation.id === operationId);
    if (index > -1) {
      this.openRemoveModal(this.operations[index]).subscribe(
        (success: boolean) => {
          if (success) {
            this.operationsService.removeOperation(operationId).subscribe(
              (data) => {
                const newOperations = this.operations;
                newOperations.splice(index, 1);
                this.setOperations(newOperations);
              }
            );
          }
        }
      );
    }
  }

  filterGenart(searchText: string) {
    if (searchText && searchText !== this.genartsSearchText) {
      this.genartsSearchText = searchText;
      this.genarts = this.allGenarts.filter((genart: Genart) => {
        return genart[`concatenation${this.lang}`].toLowerCase().includes(searchText.toLowerCase());
        // return genart.concatenation.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
      });
      this.getGenartPage(true);
    } else if (!searchText) {
      this.genarts = this.allGenarts;
      this.getGenartPage(true);
    }
  }
}
