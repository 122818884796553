import { Price } from './price';
import { Moment } from 'moment';
import * as moment from 'moment';
import { Recommendation } from './recommendation';

export class Promotion {

  constructor( public id?: number,
		  	   public ref?: string,
               public recommendation?: Recommendation,
               public startDate?: Moment,
               public endDate?: Moment,
               public prices?: Array<Price>
  ) {}

  static mapToPromotion(data: any) {
    const recommendationData: any = {ref: data.ref};
    if (data.operation) {
      recommendationData.operation = {
        labelFr: data.operation.labelFr,
        labelEn: data.operation.labelEn,
        labelEs: data.operation.labelEs
      };
    }
    const recommendation = Recommendation.mapToRecommendation(recommendationData);

    const prices: Array<Price> = [];
    let start;
    let end;
    if (data.pricePromoNatPlanList && data.pricePromoNatPlanList.length > 0) {
      data.pricePromoNatPlanList.forEach((price) => {
        prices.push(Price.mapToPrice(price));
      });

      start = moment(data.pricePromoNatPlanList[0].startDate);
      end = moment(data.pricePromoNatPlanList[0].endDate);
    }

    return new Promotion(
      data.groupmentId,
      data.ref,
      recommendation,
      start,
      end,
      prices
    );
  }
}
