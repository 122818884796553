import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Country } from '../../core/models/country';
import { Recommendation } from '../../core/models/recommendation';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { UtilsService } from '../../core/services/utils.service';
import { tap } from 'rxjs/internal/operators/tap';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';

const RECOMMENDATIONS_PATH = '/nationalplans';
const COUNTRY_PATH = '/country';

@Injectable({
  providedIn: 'root'
})
export class RecommendationService {

  constructor(private http: HttpClient,
    private utilsService: UtilsService) { }

  getRecommendations(country: Country, search?: string, page?: number, size?: number) {
    const queryParams = this.utilsService.buildQueryParams({ search, page, size });
    const url = `${environment.baseUrl}${RECOMMENDATIONS_PATH}${COUNTRY_PATH}/${country.id}${queryParams}`;
    return this.http.get(url).pipe(
      map((data: any) => {
        const recommendations: Array<Recommendation> = [];
        data.objects.forEach((item) => {
          recommendations.push(Recommendation.mapToRecommendation(item));
        });
        return { total: data.totalCount, recommendations };
      })
    );
  }

  deleteRecommendations(country: Country) {
    const url = `${environment.baseUrl}${RECOMMENDATIONS_PATH}${COUNTRY_PATH}/${country.id}`;
    return this.http.delete(url);
  }

  import(file: File, country: Country) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(`${environment.baseUrl}${RECOMMENDATIONS_PATH}${COUNTRY_PATH}/${country.id}`, formData);
  }

  export(country: Country) {
    return this.http.get(`${environment.baseUrl}${RECOMMENDATIONS_PATH}${COUNTRY_PATH}/${country.id}/export`,
      { responseType: 'arraybuffer' }).pipe(
        tap(
          (data: any) => {
            const today = moment();
            const blob = new Blob([data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const filename = `national-plan-${country.code}-export-${today.format('L')}.xlsx`;

            if(window.navigator.msSaveBlob){
              window.navigator.msSaveBlob(blob, filename)
            }else{
              FileSaver.saveAs(blob, filename);
            }
          }
        )
      );
  }
}
