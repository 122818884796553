import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DetailService {

  constructor(private http: HttpClient) {
  }


  mapFromForm(form, choice?) {
    const result = {
      idGarages: form.idGarage,
      kTyp: form.kType,
      serviceList: [],
      vidange: form.vidange
    };
    if (form.serviceList && form.serviceList.length > 0) {
      const list = form.serviceList.filter((service) => service.operations && service.operations.length > 0);
      if (list && list.length > 0) {
        list.forEach((service) => {
          const prestation = {
            id: service.id,
            ref: service.reference,
            operations: []
          };

          if (service.operations && service.operations.length > 0) {
            service.operations.forEach((operation) => {
              prestation.operations.push({
                id: operation.id,
                ref: operation.code
              });
            });
          }

          result.serviceList.push(prestation);
        });
      }
    } else {
      delete result.serviceList;
    }

    return result;
  }

  mapToDetail(item) {
    // todo: map devis
    return item;
  }

  getDetail(form: any, choice?) {
    const headers = new HttpHeaders({
      'X-Language': form.language ? form.language : '',
      'X-Country': form.country ? form.country : '',
    //  'X-Ktyp': form.ktyp ? form.ktyp : '',
      'X-Skip-Interceptor': ''
    });

    const body = this.mapFromForm(form, choice);
    return this.http.post(`${environment.baseUrl.replace('/admin', '')}/aqt`, body, {headers: headers}).pipe(
      map(this.mapToDetail)
    );
  }
}
