import {Component} from '@angular/core';
import {I18nService} from '../core/services/i18n.service';
import {ToastService} from '../core/services/toast.service';
import {ToastDetailledErrorService} from '../core/services/toast-detailled-error.service';
import {LoginService} from '../core/services/login.service';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-backoffice',
  templateUrl: './backoffice.component.html',
  styleUrls: ['./backoffice.component.scss']
})
export class BackofficeComponent {

  public sideMenuShown = true;

  constructor(private i18nService: I18nService,
              public toastService: ToastService,
              public toastDetailledErrorService: ToastDetailledErrorService) {
    this.i18nService.setLocale('fr');
  }
}
