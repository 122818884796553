// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-overhaul',
//   templateUrl: './overhaul.component.html',
//   styleUrls: ['./overhaul.component.scss']
// })
// export class OverhaulComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {
//   }

// }


import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {OverhaulService} from './overhaul.service';
import {OverhaulStates} from './overhaul-state';
import {Moment} from 'moment';
import { OperationDiagnostic } from '../../core/models/operationDiagnostic';
import { jsonpCallbackContext } from '@angular/common/http/src/module';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-overhaul',
  templateUrl: './overhaul.component.html',
  styleUrls: ['./overhaul.component.scss']
})
export class OverhaulComponent implements OnInit {

  public isLoading = false;
  public stateList = OverhaulStates;
  currentState: OverhaulStates;
  @Input() previousForm;
  @Output() resultOutput = new EventEmitter<any>();
  form = {
    gearbox: '',
    possibleEquipments: [],
    commissioningDate: '',
    km: '',
    ktyp: ''
  };

  quotationBody = {
    idGarages: [],
    kTyp: '',
    language: '',
    country: '',
    serviceList: [],
    operationRevision: {
      principalOperations: [],
      additionalOperations: []
    },
    // operationRevision: [],
    revision: true,
    vidange: false
  };
  public today = new Date();
  public selectedKilometers: 'next' | 'last';
  public selectedPlan = {
    id: null,
    ref: '',
    operations: {
      PrincipleWorkPositions: '',
      AdditionalWorkPositions: []
    }
  };
  public kilometers: any = undefined;
  public formatedKilometers: any = undefined;
  public selectedAdditionalWorkList = [];
  public selectedPrincipleWorkList = [];
  public workPositions = {
    PrincipleWorkPositions: '',
    AdditionalWorkPositions: '',
    ServiceWorkPositions: [],
    AddWorkPositions: []
  };
  public isError = false;
  public errorMessage = '';


  public typeId: string = '';

  public questionList: { id: number, description: string }[];
  public filterList: { id: number, description: string }[];

  constructor(private overHaulService: OverhaulService) {
  }

  ngOnInit() {
    this.sendPreviousFormToQuotationBody();
    this.getQuestions();
  }

  sendPreviousFormToQuotationBody() {
    this.isLoading = true;
    // this.currentState = this.stateList.QUESTIONS;
    this.currentState = this.stateList.MAINTENANCE_PLAN;

    // TO REMOVE IF ROLLBACK TO MULTI-PAGES
    this.getWorks();

    this.quotationBody.idGarages = this.previousForm.idGarages;
    this.quotationBody.kTyp = this.form.ktyp = this.previousForm.kTyp;
    this.quotationBody.country = this.previousForm.country;
    this.quotationBody.language = this.previousForm.language;
    this.quotationBody.serviceList = this.previousForm.serviceList || [];
    // this.quotationBody.revision = this.previousForm.isRevision;
    // this.quotationBody.vidange = this.previousForm.isDrain;
  }


  getQuestions() {
    this.overHaulService.getQuestions({
      kType: this.quotationBody.kTyp,
      Xcountry: this.quotationBody.country,
      XLanguage: this.quotationBody.language
    })
      .subscribe((data: {typeId: string, questionsList: { id: number, description: string }[]}) => {
        this.typeId = data.typeId;
        this.questionList = data.questionsList;
        this.getFilters();
      });
  }

  getFilters() {
    this.overHaulService.getFilters({
      typeId: this.typeId,
      Xcountry: this.quotationBody.country,
      XLanguage: this.quotationBody.language
    })
      .subscribe((data: { id: number, description: string }[]) => {
        this.filterList = data;
        if (this.filterList.length === 1 ) {
          this.form.possibleEquipments.push(this.filterList[0]);
        }
        this.isLoading = false;
      });
  }

  onChange(event: any) {
    this.form.gearbox = event.target.value;
  }

  getPlans() {
    this.isLoading = true;
    const body = {
      ...this.form,
      typeId: this.typeId,
      gearbox: this.form.gearbox,
      commissioningDate: this.form.commissioningDate,
      km: this.form.km,
      country: this.quotationBody.country,
      language: this.quotationBody.language,
    };
    this.overHaulService.lastNextKmAndMonth(body)
      .subscribe((data: any) => {
        this.kilometers = JSON.parse(JSON.stringify(data));
        this.formatKilometers(data);
        this.isLoading = false;
      });
  }

  formatKilometers(data: any) {
    this.formatedKilometers = [];

    if (data.NextKm) {
      const next =  {
        kms: data.NextKm,
        label: '',
        value: 'next'
      };

      const nextOperations: { [key: string]: string[] } = {};
      if (data.NextServiceAfterKmMonth && data.NextServiceAfterKmMonth.length > 0) {
        data.NextServiceAfterKmMonth.forEach((item) => {
          if (item.KorText) {
            if (!nextOperations[item.KorText]) {
              nextOperations[item.KorText] = [];
            }
            nextOperations[item.KorText].push(item.ItemMpText);
          }
        });
      }

      Object.keys(nextOperations).forEach((key) => {
        let prestations = '';
        nextOperations[key].forEach((prestation) => {
          prestations = `${prestations}, ${prestation[0].toUpperCase()}${prestation.slice(1)}`;
        });
        next.label =
          `${next.label}; <span class="bold">${key[0].toUpperCase()}${key.slice(1)}</span>:` +
          `${prestations.slice(1)}`;
      });
      next.label =
        `<div class="quotations-radio-block-custom"><span class="bold right-15px">${data.NextKm} km</span>` +
        `<span>${next.label.slice(1)}</span></div>`;

      this.formatedKilometers.push(next);
    }

    if (data.LastKm) {
      const last = {
        kms: data.LastKm,
        label: '',
        value: 'last'
      };

      const lastOperations: { [key: string]: string[] } = {};
      if (data.LastServiceBeforKmMonth && data.LastServiceBeforKmMonth.length > 0) {
        data.LastServiceBeforKmMonth.forEach((item) => {
          if (item.KorText) {
            if (!lastOperations[item.KorText]) {
              lastOperations[item.KorText] = [];
            }
            lastOperations[item.KorText].push(item.ItemMpText);
          }
        });
      }

      Object.keys(lastOperations).forEach((key) => {
        let prestations = '';
        lastOperations[key].forEach((prestation) => {
          prestations = `${prestations}, ${prestation[0].toUpperCase()}${prestation.slice(1)}`;
        });
        last.label =
          `${last.label}; <span class="bold">${key[0].toUpperCase()}${key.slice(1)}</span>:` +
          `${prestations.slice(1)}`;
      });
      last.label =
        `<div class="quotations-radio-block-custom"><span class="bold right-15px">${data.LastKm} km</span>` +
        `<span>${last.label.slice(1)}</span></div>`;

      this.formatedKilometers.push(last);
    }
  }

  getWorks() {
    this.isLoading = true;
    /*const body = {
      typeId: this.typeId,
      gearbox: this.form.gearbox,
      commissioningDate: this.form.commissioningDate,
      km: this.form.km,
      mdServiceProposal: JSON.parse(JSON.stringify(this.kilometers)),
      country: this.quotationBody.country,
      language: this.quotationBody.language
    };

    if (this.selectedKilometers === 'next' && body.mdServiceProposal && body.mdServiceProposal.LastServiceBeforKmMonth) {
      body.mdServiceProposal.LastServiceBeforKmMonth = [];
    } else if (this.selectedKilometers === 'last' && body.mdServiceProposal && body.mdServiceProposal.NextServiceAfterKmMonth) {
      body.mdServiceProposal.NextServiceAfterKmMonth = [];
    }*/

    this.overHaulService.getQuoteOperations(this.previousForm).subscribe((data: any) => {
      console.log('data==' + data);
      this.isError = false;
      if (data) {
        this.workPositions = {
          // data.additionalOperations,
                PrincipleWorkPositions: data.primaryOperations || [],
                AdditionalWorkPositions: data.additionalOperations || [],
                ServiceWorkPositions: [],
                  AddWorkPositions: []
        };
      } else {
        this.workPositions = {
          // data.additionalOperations,
                PrincipleWorkPositions:  '',
                AdditionalWorkPositions: '',
                ServiceWorkPositions: [],
                  AddWorkPositions: []
        };
      }
      // this.workPositions = {
        // PrincipleWorkPositions: [],
        // AdditionalWorkPositions: [],
      //   ServiceWorkPositions: [],
      //   AddWorkPositions: data || []
      // };
      this.isLoading = false;
    }, (error) => {
      // this.isLoading = false;
      this.isError = true;
      this.errorMessage = error.error.message;
      console.log('error-----' + (error.error.message)); });

    /**this.overHaulService.postKilometers(body).subscribe((data: any) => {
      this.workPositions = {
        AdditionalWorkPositions: data.listAdditionalWorks || [],
        ServiceWorkPositions: data.listServicesWorks || []
      };
      this.isLoading = false;
    });**/
  }

 async getQuotationRevision() {
  const listOfOperationsToSend: OperationDiagnostic[] = [];
  /*if (this.selectedKilometers === 'next') {
    this.kilometers.NextServiceAfterKmMonth.forEach(entry => listOfOperationsToSend.push(OperationDiagnostic.mapToOperation(entry)));
  } else {
    this.kilometers.LastServiceBeforKmMonth.forEach(entry => listOfOperationsToSend.push(OperationDiagnostic.mapToOperation(entry)));
  }*/

  /*this.quotationBody.operationRevision.principalOperations = listOfOperationsToSend;
  this.quotationBody.operationRevision.additionalOperations = this.selectedAdditionalWorkList;*/
  // this.quotationBody.operationRevision = this.selectedAdditionalWorkList ;
  // this.quotationBody.operationRevision = this.selectedPrincipleWorkList;


  if (this.selectedPrincipleWorkList) {
      this.selectedPrincipleWorkList.forEach((element) => {
        this.quotationBody.operationRevision.principalOperations.push({id: element.workId, ref: element.workText});
      });
  }

   if (this.selectedAdditionalWorkList) {
      this.selectedAdditionalWorkList.forEach((element) => {
        this.quotationBody.operationRevision.additionalOperations.push({id: element.workId, ref: element.workText});
      });
  }

  // this.quotationBody.operationRevision.principalOperations = priciaplOperations;
  // this.quotationBody.operationRevision.principalOperations = this.selectedPrincipleWorkList;
  // this.quotationBody.operationRevision.additionalOperations = [];

  this.isLoading = true;
  let request = null;
  let infos = null;

    await Promise.resolve(
      this.overHaulService.postQuotationRequest(this.quotationBody)
    ).then(data => {
      request = data;
    }).catch(data => {
      request = undefined;
    });

    await Promise.resolve(
      this.overHaulService.postQuotation(this.quotationBody)
    ).then(data => {
      infos = data;
    }).catch(data => {
      infos = undefined;
    });



    //  this.overHaulService.getQuoteOperations(this.previousForm).subscribe((data: any) => {
    //   this.workPositions = {
    //     // PrincipleWorkPositions: [],
    //     // AdditionalWorkPositions: [],
    //     ServiceWorkPositions: [],
    //     AddWorkPositions: data || []
    //   };
    //   this.isLoading = false;
    // });

    this.isLoading = false;
    this.resultOutput.emit({request, infos});
  }

  onDateChange(event: Moment) {
    this.form.commissioningDate = event.toDate().toISOString();
  }

  changeCurrentState(change: string) {
    if (change === 'up') {
      if (this.currentState === this.stateList.QUESTIONS && this.filterList.length > 0) {
        this.currentState = this.stateList.FILTERS;
      } else if (this.currentState === this.stateList.QUESTIONS && this.filterList.length === 0) {
        this.currentState = this.stateList.DIAGNOSTIC;
      } else if (this.currentState === this.stateList.FILTERS && this.filterList.length > 0) {
        this.currentState = this.stateList.DIAGNOSTIC;
      } else if (this.currentState === this.stateList.DIAGNOSTIC) {
        this.currentState = this.stateList.KILOMETERS;
        this.getPlans();
      } else if (this.currentState === this.stateList.KILOMETERS) {
        this.currentState = this.stateList.MAINTENANCE_PLAN;
        this.getWorks();
      } else if (this.currentState === this.stateList.MAINTENANCE_PLAN) {
        this.currentState = this.stateList.QUOTATION;
      }
    }
    if (change === 'down') {
      if (this.currentState === this.stateList.DIAGNOSTIC && this.filterList.length !== 0) {
        this.currentState = this.stateList.FILTERS;
      } else if (this.currentState === this.stateList.FILTERS) {
        this.currentState = this.stateList.QUESTIONS;
      } else if (this.currentState === this.stateList.KILOMETERS) {
        this.currentState = this.stateList.DIAGNOSTIC;
      } else if (this.currentState === this.stateList.MAINTENANCE_PLAN) {
        this.currentState = this.stateList.KILOMETERS;
      } else if (this.currentState === this.stateList.QUOTATION) {
        this.currentState = this.stateList.MAINTENANCE_PLAN;
      }
      this.isLoading = false;
    }
  }

  selectFilter(filterSelected: { id: number, description: string }) {

    const filterIndex = this.form.possibleEquipments.findIndex(filter => filterSelected.id === filter.id);
    if (filterIndex === -1) {
      this.form.possibleEquipments.push(filterSelected);
    } else {
      const newEquipments = [...this.form.possibleEquipments];
      newEquipments.splice(filterIndex, 1);
      this.form.possibleEquipments = newEquipments;
    }
  }

  // selectAdditionalWork(additionalWorkSelected: any) {
  //   const additionalWorkIndex = this.selectedAdditionalWorkList.findIndex((additionalWork) => {
  //     // return additionalWorkSelected.workId === additionalWork.id;
  //     return additionalWorkSelected.id === additionalWork.id;
  //   });
  //   if (additionalWorkIndex === -1) {
  //     // this.selectedAdditionalWorkList.push({id: additionalWorkSelected.workId, ref: additionalWorkSelected.workText});
  //     this.selectedAdditionalWorkList.push({id: additionalWorkSelected.id, ref: additionalWorkSelected.ref});
  //   } else {
  //     const newAdditionalWork = [...this.selectedAdditionalWorkList];
  //     newAdditionalWork.splice(additionalWorkIndex, 1);
  //     this.selectedAdditionalWorkList = newAdditionalWork;
  //   }
  // }

    selectAdditionalWork(additionalWorkSelected: any) {
    const additionalWorkIndex = this.selectedAdditionalWorkList.findIndex((additionalWork) => {
      // return additionalWorkSelected.workId === additionalWork.id;
      return additionalWorkSelected.workId === additionalWork.workId;
    });
    if (additionalWorkIndex === -1) {
      // this.selectedAdditionalWorkList.push({id: additionalWorkSelected.workId, ref: additionalWorkSelected.workText});
      this.selectedAdditionalWorkList.push({workId: additionalWorkSelected.workId, workText: additionalWorkSelected.workText});
    } else {
      const newAdditionalWork = [...this.selectedAdditionalWorkList];
      newAdditionalWork.splice(additionalWorkIndex, 1);
      this.selectedAdditionalWorkList = newAdditionalWork;
    }
  }






  selectPrincipleWork(principleWorkSelected: any) {
    const principleWorkIndex = this.selectedPrincipleWorkList.findIndex((principleWork) => {
      return principleWorkSelected.workId === principleWork.workId;
    });
    if (principleWorkIndex === -1) {
      // this.selectedAdditionalWorkList.push({id: additionalWorkSelected.workId, ref: additionalWorkSelected.workText});
      this.selectedPrincipleWorkList.push({workId: principleWorkSelected.workId, workText: principleWorkSelected.workText});
    } else {
      const newPrincipleWork = [...this.selectedPrincipleWorkList];
      newPrincipleWork.splice(principleWorkIndex, 1);
      this.selectedPrincipleWorkList = newPrincipleWork;
    }
  }






  isElementInArray(workId: number, array: any[], filterKey: string) {
    const foundIndex = array.findIndex(element => workId === element[filterKey]);
    return foundIndex !== -1;
  }

  isNextDisabled() {
    if (this.currentState === this.stateList.QUESTIONS && !this.form.gearbox) {
      return true;
    } else if (this.currentState === this.stateList.FILTERS ) {
      return false;
    } else if (this.currentState === this.stateList.DIAGNOSTIC && (!this.form.commissioningDate || !this.form.km)) {
      return true;
    } else if (this.currentState === this.stateList.KILOMETERS && !this.selectedKilometers) {
      return true;
    }
    // else if (this.currentState === this.stateList.MAINTENANCE_PLAN && !this.selectedPlan.id) {
    //   return true;
    // }
    return false;
  }

  addElementToArray(elementToAdd: any, selectionArray: any[], idKeyName: string) {
    const elementIndex = selectionArray.findIndex(element => elementToAdd[idKeyName] === element[idKeyName]);
    if (elementIndex === -1) {
      selectionArray.push(elementToAdd);
    } else {
      const newSelectionArray = [...selectionArray];
      newSelectionArray.splice(elementIndex, 1);
      selectionArray = newSelectionArray;
    }
  }

}
