import { Component, OnInit } from '@angular/core';
import { GridDescriptor } from '../../core/models/grid/grid-descriptor';
import { Country } from '../../core/models/country';
import { CountriesService } from '../../core/services/countries.service';
import { Observable } from 'rxjs';
import { ModalDescriptor } from '../../core/models/modal/modal-descriptor';

@Component({
  selector: 'app-countries-grid',
  templateUrl: './countries-grid.component.html',
  styleUrls: ['./countries-grid.component.scss']
})
export class CountriesGridComponent implements OnInit {
  public descriptor: GridDescriptor;
    public isModifOK:boolean = false;

  public newLineStructure: {
    label: string,
    code: string,
    currency: string,
    vatRate: number
  } = {
    label: '',
    code: '',
    currency: '',
    vatRate: undefined
  };

  public countries: Array<Country> = [];

  private modalDescriptorModel: ModalDescriptor = {
    width: 450,
    height: 200,
    title: '',
    titleParameters: {},
    message: '',
    messageParameters: {},
    showModal: false,
    actions: [],
    clickOutsideBehavior: undefined,
    clickOutsideBehaviorParameters: []
  };

  public modalDescriptor: ModalDescriptor = JSON.parse(JSON.stringify(this.modalDescriptorModel));

  public searchText = '';
  private searchTimeout: any;

  public totalItem = 0;
  public loadingPage = false;
  private lastPage = 0;

  constructor(private countriesService: CountriesService) {}

  ngOnInit() {
    this.getCountries(0);
    this.setDescriptor();
  }

  searchChanged() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.getCountries(0);
      this.searchTimeout = undefined;
    }, 300);
  }

  getNextPage() {
    if (!this.loadingPage && this.totalItem && this.countries.length < this.totalItem) {
      this.getCountries(this.lastPage + 1);
    }
  }

  getCountries(page: number) {
    this.loadingPage = true;
    this.countriesService.getCountries(this.searchText, page).subscribe(
      (result: {total: number, countries: Array<Country>}) => {
        this.totalItem = result.total;
        this.lastPage = page;
        if (result.countries) {
          if (page === 0) {
            this.setCountries(result.countries);
          } else {
            this.setCountries([...this.countries, ...result.countries]);
          }
        }
        this.loadingPage = false;
      }
    );
  }

  setCountries(countries: Array<Country>) {
    this.countries = countries;
  }

  setDescriptor() {
    this.descriptor = {
      columns: [
        {
          id: 1,
          label: 'APP.COUNTRIES_GRID.LABEL',
          labelParameters: {},
          value: 'label',
          type: 'text-only',
          required: true,
          weight: '3'
        },
        {
          id: 2,
          label: 'APP.COUNTRIES_GRID.CODE',
          labelParameters: {},
          value: 'code',
          type: 'text-only',
          required: true,
          weight: '3'
        },
        {
          id: 3,
          label: 'APP.COUNTRIES_GRID.CURRENCY',
          labelParameters: {},
          value: 'currency',
          type: 'text-only',
          required: true,
          weight: '3'
        },
        {
          id: 3,
          label: 'APP.COUNTRIES_GRID.VAT_RATE',
          labelParameters: {},
          value: 'vatRate',
          type: 'number',
          suffix: '%',
          required: true,
          weight: '2'
        }
      ],
      actions: [
        {
          label: 'remove',
          tooltip: 'APP.COUNTRIES_GRID.REMOVE_LINE_TOOLTIP',
          icon: 'fa-trash error-button',
          behavior: this.removeCountry.bind(this),
          confirmation: true,
          parameters: []
        }
      ],
      actionWeight: '1',
      newLine: true
    };
  }

  addLine(newLine: {label: string, code: string, currency: string, id?: number}) {
    if (newLine.id) {
      this.countriesService.setLine(newLine).subscribe(
        (country: Country) => {
          const index = this.countries.findIndex((d) => d.id === country.id);
          if (index > -1) {
            this.countries[index] = country;
          } else {
            this.countries.push(country);
          }
          this.isModifOK = !this.isModifOK;
        }
      );
    } else {
      this.countriesService.addLine(newLine).subscribe(
        (newCountry: Country) => {
          this.countries.push(newCountry);
          this.isModifOK = !this.isModifOK;
        }
      );
    }
  }

  closeModal() {
    this.modalDescriptor = JSON.parse(JSON.stringify(this.modalDescriptorModel));
  }

  confirmModal(observer) {
    observer.next(true);
    observer.complete();
    this.closeModal();
  }

  cancelModal(observer) {
    observer.next(false);
    observer.complete();
    this.closeModal();
  }

  openRemoveModal(country: Country) {
    return new Observable((observer) => {
      this.modalDescriptor.title = 'APP.COUNTRIES_GRID.REMOVE_MODAL_TITLE';
      this.modalDescriptor.message = 'APP.COUNTRIES_GRID.REMOVE_MODAL_MESSAGE';
      this.modalDescriptor.actions.push({
        icon: '',
        label: 'APP.ACTIONS.CANCEL',
        color: 'white',
        behavior: this.cancelModal.bind(this),
        behaviorParameters: [observer]
      });
      this.modalDescriptor.actions.push({
        icon: '',
        label: 'APP.ACTIONS.CONFIRM',
        color: 'red',
        behavior: this.confirmModal.bind(this),
        behaviorParameters: [observer]
      });
      this.modalDescriptor.clickOutsideBehavior = this.cancelModal.bind(this);
      this.modalDescriptor.clickOutsideBehaviorParameters = [observer];
      this.modalDescriptor.showModal = true;
    });
  }

  removeCountry(countryId: number) {
    const index = this.countries.findIndex((country) => country.id === countryId);
    if (index > -1) {
      this.openRemoveModal(this.countries[index]).subscribe(
        (success: boolean) => {
          if (success) {
            this.countriesService.removeCountry(countryId).subscribe(
              (data) => {
                const newCountries = this.countries;
                newCountries.splice(index, 1);
                this.setCountries(newCountries);
              }
            );
          }
        }
      );
    }
  }
}
