import { Component } from '@angular/core';
import { LoginService } from './core/services/login.service';
import { I18nService } from './core/services/i18n.service';
import { environment } from '../environments/environment';
import { ToastService } from './core/services/toast.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public sideMenuShown = true;

  public environmentTest = environment.testMode;

  constructor(private i18nService: I18nService,
              public toastService: ToastService,
              public loginService: LoginService) {
    this.i18nService.setLocale('fr');
  //  this.loginService.login(this.getMyToken());
  }
 // getMyToken(): string {
  // tslint:disable-next-line: max-line-length
   // return 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJFNTk2MzQ2IiwiUk9MRVMiOiJBUVQuQURNSU4sQVFULkVUVURFUy5BRE1JTixBUVQuUE9BX0NFTlRSQUwiLCJleHAiOjE2Njc0NzkwODEsImNvdW50cnkiOltdfQ.sIE5r9wySuv_Lexe_ACcaN0yPypWTfdrcO7vleoVNlx49HA1-Pqy87TWu_A4zlbxAArfs9uAb0o8Jtzj_VEU6Q';
   // }
}
