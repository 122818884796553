import { Component, Input, OnInit } from '@angular/core';
import { ModalAction } from '../models/modal/modal-action';

@Component({
  selector: 'app-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.scss']
})
export class CustomModalComponent implements OnInit {


  // --------------------------------------------------
  //                     PROPERTIES
  // --------------------------------------------------

  @Input() actions: Array<ModalAction> = [];
  @Input() title: string;
  @Input() titleParameters: any = {};
  @Input() message: string;
  @Input() messageParameters: any = {};
  @Input() height: number;
  @Input() width: number;
  @Input() clickedOutsideModalBehavior: Function;
  @Input() clickedOutsideModalBehaviorParameters: any[];

  // --------------------------------------------------
  //                     CONSTRUCTOR
  // --------------------------------------------------

  constructor() {}


  // --------------------------------------------------
  //                     METHODS
  // --------------------------------------------------

  /**
   * On init, instantiate login form builder.
   */
  ngOnInit() {}

  clickOutsideModal() {
    this.clickedOutsideModalBehavior(...this.clickedOutsideModalBehaviorParameters);
  }

  doAction(action: ModalAction) {
    action.behavior(...action.behaviorParameters);
  }

  getModalStyle() {
    return {
      width: `${this.width}px`,
      height: `${this.height}px`,
      top: `calc(50% - ${this.height / 2}px)`,
      left: `calc(50% - ${this.width / 2}px)`
    };
  }
}
