import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '../../core/core.module';
import { RadioButtonComponent } from './radio-button.component';

@NgModule({
    declarations: [
        RadioButtonComponent
    ],
    imports: [
        CoreModule,
        FormsModule,
        TranslateModule
    ],
    exports: [
        RadioButtonComponent
    ]
})
export class RadioButtonModule {}
