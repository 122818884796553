import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginService } from './login.service';
import { I18nService } from './i18n.service';

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {

  constructor(private loginService: LoginService, private i18nService: I18nService) {}

  intercept(request: HttpRequest<any>, httpHandler: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.has('X-Skip-Interceptor')) {
      const headers = request.headers.delete('X-Skip-Interceptor');

      return httpHandler.handle(request.clone({ headers }));
    } else {
      let authRequest;
      let country;
      if (request.headers.has('X-Country')) {
        country = request.headers.get('X-Country');
      }

      if (this.loginService.token) {

        if(country){
          authRequest = request.clone({
            headers: new HttpHeaders({
              Authorization: this.loginService.token,
              'X-Language': this.i18nService.lang,
              'X-Country' : country
            })
          });
        } else {
          authRequest = request.clone({
            headers: new HttpHeaders({
              Authorization: this.loginService.token,
              'X-Language': this.i18nService.lang
            })
          });
        }

      } else {

        if(country){

          authRequest = request.clone({
            headers: new HttpHeaders({
              'X-Language': this.i18nService.lang,
              'X-Country' : country
            })
          });

        } else {
          authRequest = request.clone({
            headers: new HttpHeaders({
              'X-Language': this.i18nService.lang
            })
          });
        }


      }
      return httpHandler.handle(authRequest);
    }
  }
}
