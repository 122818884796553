import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '../../../core/services/utils.service';
import { Rule } from '../../../core/models/rule';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Country } from '../../../core/models/country';

const RULES_PATH = '/priorityrule';
const COUNTRY_PATH = '/country';

@Injectable({
  providedIn: 'root'
})
export class RulesService {

  constructor(private http: HttpClient, private utilsService: UtilsService) {}

  getRules(country: Country, search?: string, page?: number) {
    const queryParams = this.utilsService.buildQueryParams({search, page});
    return this.http.get(`${environment.baseUrl}${RULES_PATH}${COUNTRY_PATH}/${country.id}${queryParams}`).pipe(
      map((data: any) => {
        return {total: data.totalCount, rules: data.objects};
      })
    );
  }

  addLine(country: Country, newLine: Rule) {
    const body = {
      thresholdPrice: newLine.thresholdPrice,
      country
    };

    return this.http.post(`${environment.baseUrl}${RULES_PATH}`, body);
  }

  setLine(country: Country, newLine: Rule) {
    const body = {
      id: newLine.id,
      thresholdPrice: newLine.thresholdPrice,
      country
    };

    return this.http.put(`${environment.baseUrl}${RULES_PATH}`, body);
  }

  removeRule(ruleId: number) {
    return this.http.delete(`${environment.baseUrl}${RULES_PATH}/${ruleId}`);
  }
}
