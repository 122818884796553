import { Genart } from './genart';

export const OPTYPES = [
  {value: 'GLOBAL', label: 'APP.OPTYPE.GLOBAL'},
  {value: 'MO', label: 'APP.OPTYPE.MO'},
  {value: 'QUOT_DETAILS', label: 'APP.OPTYPE.QUOT_DETAILS'},
  {value: 'OVERHAUL', label: 'APP.OPTYPE.OVERHAUL'},
  {value: 'DETAILED_REVISION', label: 'APP.OPTYPE.DETAILED_REVISION'}
];

export class Operation {

  constructor( public id?: number,
               public code?: string,
               public labelFr?: string,
               public labelEn?: string,
               public labelEs?: string,
               public prestationId?: number,
               public brrNumber?: number,
               public type?: string,
               public genarts?: Genart[]
  ) {}

  static mapToOperation(data) {
    let prestationId: number;
    if (data.product && data.product.id) {
      prestationId = data.product.id;
    }

    let type;
    if (data.operationEnum) {
      const index = OPTYPES.findIndex((TYPE) => data.operationEnum === TYPE.value);
      if (index > -1) {
        type = { value: OPTYPES[index].value, label: OPTYPES[index].label };
      }
    }

    const genarts = [];
    if (data.genarts) {
      data.genarts.forEach((genart: any) => {
        genarts.push(Genart.mapToGenart(genart));
      });
    }

    return new Operation(
      data.id,
      data.ref,
      data.labelFr,
      data.labelEn,
      data.labelEs,
      prestationId,
      data.brrColumn,
      type,
      genarts
    );
  }
}
