import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastDetailledErrorService {

  public errorMessage: string;
  public errorStatus: string;

  public messageDetail : string[];


  constructor() {}

  setToastDetailledError(errorMessage: string, errorStatus: string, messageDetail: string[]) {
    this.errorMessage = errorMessage;
    this.errorStatus = errorStatus;
    this.messageDetail = messageDetail;
  }

  resetToastDetailledError() {
    this.errorMessage = '';
    this.errorStatus = '';
    this.messageDetail = [];
  }
}
