import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { GridDescriptor } from '../models/grid/grid-descriptor';
import { GridAction } from '../models/grid/grid-action';
import { GridColumnDescriptor } from '../models/grid/grid-column-descriptor';
import { I18nService } from '../services/i18n.service';

@Component({
  selector: 'app-generic-grid',
  templateUrl: './generic-grid.component.html',
  styleUrls: ['./generic-grid.component.scss']
})
export class GenericGridComponent implements OnChanges {

  @Input() gridData: Array<any> = [];
  @Input() gridDescriptor: GridDescriptor;
  @Input() editable: boolean;
  @Input() newLineStructure: any;
  @Input() isModifOK: boolean;

  @Output() newLineAdded = new EventEmitter<any>();

  public errors: {[key: string]: string} = {};
  public newLine: any = {};
  public localLine: any = {};
  public editingLine: number;

  constructor(public i18nService: I18nService) {}

  ngOnChanges(changes: SimpleChanges): void {
     if (changes.isModifOK && !changes.isModifOK.firstChange) {
        this.cancelEdition();
     }
  }

  getColumnWeightStyle(weight: string) {
    const browsers = window.navigator.userAgent;
    if ( (browsers.indexOf('Trident/7.0') > -1) && this.gridDescriptor.totalWeight) {
      return {
        'flex-grow': '0',
        'flex-shrink': '1',
        'flex-basis': `${+weight * (100 / this.gridDescriptor.totalWeight)}%`
      };
    } else {
      return {flex: weight};
    }
  }

  editLine(lineIndex?: number) {
    this.errors = {};

    this.newLine = JSON.parse(JSON.stringify(this.newLineStructure));
    this.localLine = JSON.parse(JSON.stringify(this.newLineStructure));
    if (lineIndex !== undefined) {
      this.editingLine = lineIndex;
      this.gridDescriptor.columns.forEach((column) => {
        if (this.gridData[lineIndex] && Array.isArray(this.gridData[lineIndex][column.value])) {
          // deep copy for array
          this.localLine[column.value] = [...this.gridData[lineIndex][column.value]];
        } else {
          // get ref for others
          this.localLine[column.value] = this.getItemValue(this.gridData[lineIndex], column.value);
        }
      });
      if (this.gridData[lineIndex].id) {
        this.localLine.id = this.gridData[lineIndex].id;
      }
    } else {
      this.editingLine = this.gridData.length;
    }
  }

  cancelEdition() {
    this.editingLine = undefined;
    this.localLine = {};
  }

  saveLine() {
    // if no errors are present format the line
    if (Object.keys(this.errors).length < 1) {

      // for each column
      this.gridDescriptor.columns.forEach((column) => {

        // if column is required and the line has no value, raise an error flag
        if (column.required &&
            (!this.localLine[column.value] || (Array.isArray(this.localLine[column.value]) &&
            this.localLine[column.value].length < 1))) {
          this.errors[column.value] = 'APP.GRID.ERRORS.REQUIRED';
        } else {
          // column paths matchs the format 'key1.key2.key3...'
          // split the string to get each atomic path
          const paths = column.value.split('.');
          // identify the last path
          const lastPath = paths[paths.length - 1];
          // for each step of the paths depth
          paths.reduce((built, next) => {
            if (next !== lastPath) {
              // create the object if it doesn't exists
              if (!built[next]) {
                built[next] = {};
              }
              // return the matching object
              return built[next];
            } else {
              // for the last path set the correct value
              built[lastPath] = this.localLine[column.value];
              return built[lastPath];
            }
          }, this.newLine);
        }
      });

      // if no error flags have been raised during formatting notify parent of the update
      if (Object.keys(this.errors).length < 1) {
        if (this.localLine.id) {
          this.newLine.id = this.localLine.id;
        }
        this.newLineAdded.emit(this.newLine);
//         this.editingLine = undefined;
//         this.localLine = {};
      }
    }
  }

  doAction(action: GridAction, item: any, itemIndex: number) {
    action.behavior(item.id, itemIndex);
  }

  getItemValue(item: any, givenPath: string) {
    return givenPath.split('.').reduce((built, next) => {
      return built ? built[next] : null;
    }, item);
  }

  getItemDisplay(item: any, column: GridColumnDescriptor) {
    let result: string | {key: string, params: any} = '';
    if (!column.display) {
      result = this.getItemValue(item, column.value);
    } else {
      if (column.subType === 'i18n') {
        const params = {};
        if (column.displayParams && column.displayParams.length > 0) {
          column.displayParams.forEach((param) => {
            params[param.key] = param.value.split('.').reduce((built, next) => {
              return built ? built[next] : null;
            }, item);
          });
        }
        return {key: column.display, params};
      } else {
        result = column.display.split('.').reduce((built, next) => {
          return built ? built[next] : null;
        }, item);
      }
    }
    return result ? `${result}` : '';
  }

  onFieldChange(column: GridColumnDescriptor) {
    // if previous error is present remove it
    delete this.errors[column.value];
    if (column.fieldValidator) {
      // check if there is a validator and, if so, check it
      if (column.fieldValidator.isValid && !column.fieldValidator.isValid(
        this.localLine[column.value],
        this.localLine,
        ...column.fieldValidator.validationParams)
      ) {
        // if the field is invalid set error
        this.errors[column.value] = column.fieldValidator.message;
      }

      // run linked fields validations
      column.fieldValidator.linkedValidation.forEach((fieldId) => {
        // retrieve linked field
        const foundIndex = this.gridDescriptor.columns.findIndex((field) => field.id === fieldId);
        if (foundIndex > -1) {
          const linkedColumn = this.gridDescriptor.columns[foundIndex];

          // if previous error is present remove it
          delete this.errors[linkedColumn.value];

          // check if there is a validator and, if so, check it
          if (linkedColumn.fieldValidator.isValid && !linkedColumn.fieldValidator.isValid(
              this.localLine[linkedColumn.value],
              this.localLine,
              ...linkedColumn.fieldValidator.validationParams)
          ) {
            // if the linked field is invalid set error
            this.errors[linkedColumn.value] = linkedColumn.fieldValidator.message;
          }
        }
      });
    }
  }
}
