import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { User } from '../models/user';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public isLogged = false;
  public logging = false;
  public attemptedRoute: string;
  public token: string;

  constructor(private router: Router, private userService: UserService, private http: HttpClient) {}

  init(url: string) {
    if (url) {
      this.attemptedRoute = url;
    } else if (localStorage.getItem('attempted-route')) {
      this.attemptedRoute = localStorage.getItem('attempted-route');
    }
    if (this.logging) {
      return false;
    }
    if (this.isLogged) {
      return true;
    }
    if (this.userService.user && this.token) {
      this.isLogged = true;
      return true;
    }
    if (localStorage.getItem('token')) {
      this.login(localStorage.getItem('token'));
      return true;
    }
    window.location.href = `${environment.authUrl}/saml/login`;
    return false;
  }

  login(token) {
    localStorage.setItem('token', token);
    this.token = token;

    const base64 = token.split('.')[1];
    const user: User = User.mapToUser(JSON.parse(atob(base64)));
    this.userService.setUser(user);

    if (this.attemptedRoute) {
      const paths = this.attemptedRoute.split('/');
      if (paths && paths.length > 1) {
        this.router.navigate(this.attemptedRoute.split('/'));
      } else {
        this.router.navigate(['backoffice', 'standard-grid']);
      }
    } else {
      this.router.navigate(['backoffice', 'standard-grid']);
    }
  }

  getToken() {
    const headers = new HttpHeaders({
      'X-Skip-Interceptor': ''
    });

    return this.http.get(`${environment.authUrl}/token/me`, {headers, observe: 'response', withCredentials: true});
  }

  timeoutError() {
    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
    }
    localStorage.setItem('attempted-route', this.attemptedRoute);
    window.location.href = `${environment.authUrl}/saml/login`;
  }

  logout() {
    this.isLogged = false;
    this.logging = false;
    this.token = '';
    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
    }

    window.location.href = `${environment.authUrl}/saml/logout`;
  }
}
