export class User {

  constructor( public id?: number,
               public name?: string,
               public country?: string,
               public roles?: string[],
               public exp?: string
  ) {}

  static mapToUser(data: any) {
    const rolesTab = data.ROLES.split(',');
    return new User(
      data.sub,
      data.sub,
      data.country,
      rolesTab,
      data.exp
    );
  }
}
