import { Component, Input } from '@angular/core';
import { ToastService } from '../services/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent {

  @Input() message: string;
  @Input() status: string;

  constructor(private toastService: ToastService) {}

  closeToast() {
    this.toastService.resetToast();
  }
}
