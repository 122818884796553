import { LOCALE_ID, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { StandardGridModule } from './standard-grid/standard-grid.module';
import { PromotionGridModule } from './promotion-grid/promotion-grid.module';
import { DiscountGridModule } from './discount-grid/discount-grid.module';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';
import { I18nService } from './core/services/i18n.service';
import { ConfigurationModule } from './configuration/configuration.module';
import { EstimatesModule } from './estimates-component/estimates.module';
import { BackofficeComponent } from './backoffice/backoffice.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LandingComponent } from './landing/landing.component';
// import { DetailComponent } from './detail/detail.component';
// import { OverhaulComponent } from './detail/overhaul/overhaul.component';
import { DetailModule } from './detail/detail.module';
// import { DetailcomponentComponent } from './detailcomponent/detailcomponent.component';
// import { DetailComponentComponent } from './detail-component/detail-component.component';

registerLocaleData(localeFr, 'fr-FR');
registerLocaleData(localeEs, 'es-ES');

@NgModule({
  declarations: [
    AppComponent,
    BackofficeComponent,
    LandingComponent,
    // DetailcomponentComponent,
    // DetailComponentComponent,
   // DetailComponent,
   // OverhaulComponent
  ],
  imports: [
    CoreModule,
    EstimatesModule,
    ConfigurationModule,
    StandardGridModule,
    PromotionGridModule,
    DiscountGridModule,
    BrowserAnimationsModule,
    DetailModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      deps: [I18nService],
      useFactory: (i18nService) => i18nService.locale
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
