import { Component, ViewChild, OnInit, Output, Input, EventEmitter } from '@angular/core';

import * as moment from 'moment';
import { Moment } from 'moment';
import { CalendarComponent } from '../calendar/calendar.component';
import { DatePickerService } from './date-picker.service';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {

  // --------------------------------------------
  //           PROPERTIES
  // --------------------------------------------

  /**
   * Selected day.
   */
  @Input()
  public selectedDay: Moment;

  @Input() isErrorState: boolean;

  @Input() name: string;

  @Output()
  public selectedDayChange = new EventEmitter<Moment>();

  @ViewChild(CalendarComponent) child: CalendarComponent;

  public dayValue: string;
  public monthValue: string;
  public yearValue: string;

  public isDropdownOpen = false;

  /**
   * Arrays of keycode authorized in date picker inputs.
   *
   * keyCodeAccepted represents NumPad number.
   * keyCodeNumberAccepted represents classic key number, which needs Shift key.
   * keyCodeMove represents Tab, Delete, Right Arrow and Left Arrow keys.
   */
  public readonly keyCodeAccepted: number[] = [8, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105];
  public readonly keyCodeNumberAccepted: number[] = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
  public readonly keyCodeMove: number[] = [9, 37, 39, 46];

  public unitsOrder = {
    day: -1,
    month: -1,
    year: -1
  };

  public dateSplitter: string;



  // --------------------------------------------
  //           CONSTRUCTOR
  // --------------------------------------------

  constructor(
    public datePickerService: DatePickerService
  ) { }


  // --------------------------------------------
  //            METHODS
  // --------------------------------------------

  /**
   * On init, get order of units, the date splitter and selectedDay
   */
  ngOnInit() {
    this.datePickerService.getFieldsOrder().subscribe(
      (fieldsOrder: any) => {
        this.unitsOrder = fieldsOrder;
      }
    );
    // this.unitsOrder = this.datePickerService.getUnitsOrder();
    this.dateSplitter = this.datePickerService.getDateSplitter();

    if (this.selectedDay !== undefined) {
      this.dayValue = this.selectedDay.format('DD');
      this.monthValue = this.selectedDay.format('MM');
      this.yearValue = this.selectedDay.format('YYYY');
      this.selectedDayChanged(this.selectedDay);

    } else {
      this.selectedDay = moment();
    }

    this.getSelectedDay();
  }

  /**
   * Change the dropdown state.
   */
  public changeDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  /**
   * Close the dropdown.
   */
  public closeDropdown(): void {
    this.isDropdownOpen = false;
  }

  /**
   * Open the calendar on date picker inputs focused.
   */
  openDropdown(): void {
    if (!this.isDropdownOpen) {
      this.isDropdownOpen = true;
    }
  }

  /**
   * Get selected day and set inputs values.
   */
  getSelectedDay(): void {
    this.datePickerService.getSelectedDay().subscribe(
      (wrapper: {selectedDay: Moment, name: string}) => {
        if (wrapper && wrapper.name === this.name) {
          this.selectedDay = wrapper.selectedDay;
          this.dayValue =
              (Number(this.selectedDay.format('D')) > 3) ? this.selectedDay.format('DD') : this.selectedDay.format('D');
          this.monthValue =
              (Number(this.selectedDay.format('M')) > 1) ? this.selectedDay.format('MM') : this.selectedDay.format('M');
          this.yearValue = this.selectedDay.format('YYYY');
        }
      }
    );
  }

  /**
   * Bind the picker date with the input date.
   * If the picker date change, update the input date, and vice versa.
   */
  selectedDayChanged(date?: Moment): void {
    if (date !== undefined) {
      this.datePickerService.setSelectedDay(date, this.name);
      this.selectedDayChange.emit(date);
      this.closeDropdown();
    } else {
      this.datePickerService.setDateValues(this.dayValue, this.monthValue, this.yearValue);
      if (this.datePickerService.isInputsFilled() && this.datePickerService.dateMomentValidator()) {
        this.datePickerService.setSelectedDay(undefined, this.name);
        this.child.setDateByPicker(this.selectedDay);
        this.selectedDayChange.emit(this.selectedDay);
      }
    }
  }

  /**
   * On key down press, get previous and next inputs. Verified if the key is valid (number or "Tabulation", "Delete" ... keys).
   * Then allows to go on next input or back on previous input.
   */
  onKey(event: any, unit: string): void {
    const previousValue = this.datePickerService.getPreviousInput(unit);
    const previousInput = previousValue ? this[previousValue + 'Input'] : undefined;

    const nextValue = this.datePickerService.getNextInput(unit);
    const nextInput = nextValue ? this[nextValue + 'Input'] : undefined;

    if ((this.keyCodeAccepted.indexOf(event.keyCode) > -1)
        || ((this.keyCodeNumberAccepted.indexOf(event.keyCode) > -1) && event.shiftKey)) {

      // If input length is maximum, key event is not BackSpace and input value is not selected, focus next input.
      if (event.target.getAttribute
        && event.target.value.length === Number(event.target.getAttribute('maxlength'))
        && event.keyCode !== 8
        && document.getSelection().type !== 'Range'
      ) {
        nextInput ? nextInput.nativeElement.focus() : event.preventDefault();

      // If key event is BackSpace and input is empty, focus previous input.
      } else if ((event.target.value.length === 0) && (event.keyCode === 8)) {
        previousInput ? previousInput.nativeElement.focus() : event.preventDefault();
      }

    } else if (this.keyCodeMove.indexOf(event.keyCode) === -1) {
      // forbids the propagation of other characters than the number.
      event.preventDefault();
    }
  }
}
