import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ToastService } from './toast.service';
import { LoginService } from './login.service';
import { ToastDetailledErrorService } from './toast-detailled-error.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private loginService: LoginService,
                private toastService: ToastService,
                private toastDetailledErrorService: ToastDetailledErrorService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request).pipe(catchError((err) => {
          this.toastDetailledErrorService.resetToastDetailledError();
          if (err.status === 401) {
            this.loginService.timeoutError();
            this.toastService.setToast('ERRORS.401', 'warning');
          } else if (err.status === 400) {
            if (err.error && err.error.message && err.error.message.length > 1) {
              this.toastDetailledErrorService.setToastDetailledError(err.error.message, 'error', null);
            } else {
              this.toastService.setToast('ERRORS.400', 'error');
            }
          } else if (err.status === 404) {
            this.toastService.setToast('ERRORS.404', 'error');
          } else {
              if (err.error && err.error.message && err.error.message.length > 1) {
                this.toastService.setToast(err.error.message, 'error');
              } else {
                this.toastService.setToast('ERRORS.GENERIC', 'error');
              }
          }
          return throwError(err);
      }));
    }
}
