import { Component, OnInit } from "@angular/core";
import { Country } from "src/app/core/models/country";
import { CountriesService } from "src/app/core/services/countries.service";
import { GridDescriptor } from "src/app/core/models/grid/grid-descriptor";
import { OilscontainerService } from "./services/oilscontainer.service";
import { MatTableDataSource } from "@angular/material";

export class OilContainerRowRepresentation {
  public idCountry: number;
  public priorityOrder: number;
  public oilContainerTypeId: number;
  public oilContainerType: string;
  public fcc: boolean;
}

@Component({
  selector: "app-oils-container",
  templateUrl: "./oils-container.component.html",
  styleUrls: ["./oils-container.component.scss"],
})
export class OilsContainerComponent implements OnInit {
  public selectedCountry: Country;
  public countries: Array<Country> = [];
 
  public oilColumns: any[] = ['Priorityorder', 'oils', 'Action', 'Oil'];

  public dataSource: MatTableDataSource<OilContainerRowRepresentation>;

  constructor(
    private countriesService: CountriesService,
    private oilscontainerService: OilscontainerService
  ) { }

  ngOnInit() {
    this.getCountries();
  }

  getCountries(searchText?: string) {
    this.countriesService
      .getCountries(searchText)
      .subscribe((result: { total: number; countries:  Array<Country >}) => {
        if (result.countries && result.countries.length > 0) {
          this.setCountries(result.countries);
        }
      });
  }

  setCountries(countries: Array<Country>) {
    this.countries = countries;
    if (this.countriesService.countryId) {
      this.selectedCountry = this.countries.find(
        (country: Country) => country.id === this.countriesService.countryId
      );
      this.loadOilContainerConfigurationForSelectedCountry();
    }
  }
  loadOilContainerConfigurationForSelectedCountry() {
    if (this.selectedCountry) {
      this.oilscontainerService
        .getOilByCountry(this.selectedCountry)
        .subscribe((data: Array<OilContainerRowRepresentation>) => {
          this.updateOilContainerData(data);
        });
    }
  }
  updateOilContainerData(oilContainerData: Array<OilContainerRowRepresentation>) {
    oilContainerData = this.sortOilContainerByPriorityOrder(oilContainerData);
    this.dataSource = new MatTableDataSource(oilContainerData);
  }

  onCountryChange(country: Country) {
    this.selectedCountry = country;
    this.countriesService.setCountry(country.id);
    this.oilscontainerService
      .getOilByCountry(this.selectedCountry)
      .subscribe((data: Array<OilContainerRowRepresentation>) => {
        this.updateOilContainerData(data);
      });
  }

  sortOilContainerByPriorityOrder(data: Array<OilContainerRowRepresentation>) {
    return data.sort((a, b) => a.priorityOrder - b.priorityOrder);
  }


  saveNeworder(oilContainerData: Array<OilContainerRowRepresentation>) {
    this.oilscontainerService.updateOilList(oilContainerData)
      .subscribe(
        result => {

          this.loadOilContainerConfigurationForSelectedCountry();
        },
        error => {
          // this.errors = error;
          this.loadOilContainerConfigurationForSelectedCountry();
        },
        () => {
          // 'onCompleted' callback.
          // No errors, route to new page here
        }
      );
  }

  arrowdown(index: number) {
    this.dataSource.data[index].priorityOrder = this.dataSource.data[index].priorityOrder + 1;
    this.dataSource.data[index + 1].priorityOrder = this.dataSource.data[index + 1].priorityOrder - 1;
    this.dataSource.data = this.dataSource.data.sort((a, b) => a.priorityOrder - b.priorityOrder);
    this.saveNeworder(this.dataSource.data);
  }

  arrowup(index: number) {
    this.dataSource.data[index].priorityOrder = this.dataSource.data[index].priorityOrder - 1;
    this.dataSource.data[index - 1].priorityOrder = this.dataSource.data[index - 1].priorityOrder + 1;
    this.dataSource.data = this.dataSource.data.sort((a, b) => a.priorityOrder - b.priorityOrder);
    this.saveNeworder(this.dataSource.data);
  }

  fccChanged(event: any, index: number){
    this.dataSource.data[index].fcc = event.checked;
    this.saveNeworder(this.dataSource.data);
  }

}
