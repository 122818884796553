import {Component, OnInit} from '@angular/core';
import {Operation} from '../core/models/operation';
import {Prestation} from '../core/models/prestation';
import {PrestationsService} from '../core/services/prestations.service';
import {OperationsService} from '../configuration/operations-grid/services/operations.service';
import {I18nService} from '../core/services/i18n.service';
import {EstimatesService} from './estimates.service';
import {EstimatesrequestService} from './estimatesrequest.service';
import {MatTabsModule} from '@angular/material/tabs';
import { LoginService } from '../core/services/login.service';

@Component({
  selector: 'app-estimates-grid',
  templateUrl: './estimates.component.html',
  styleUrls: ['./estimates.component.scss']
})
export class EstimatesComponent implements OnInit {
  public searchText: string;
  public result = null;
  public form: {
    language: string,
    country: string,
    kType: number,
    idGarage: string[],
    serviceList: Prestation[]
  } = {
    language: '',
    country: '',
    kType: undefined,
    idGarage: [],
    serviceList: []
  };
  private previousForm = null;
  public newGarage = '';

  public fetchingQuotation = false;

  public operations: Array<Operation> = [];
  public loadingPage = false;

  private lastPage = 0;
  private totalItem = 0;
  private searchTimeout: any;

  public lang = 'En';

  public hideMapping = false;

  public estimatesState = 'form';
  public hasDisplay = false;
  public infos: any;
  public requestdisplay: any;
  public displayNotFound = false;
  public hasRequestDisplay = false;

  public selectedChoices: number = undefined;

  public originalPrestations: Prestation[];
  public prestationsMetaInfo: {
    [prestationId: string]: {
      opened?: boolean,
      enabled?: boolean,
      reference?: string,
      operations?: Operation[],
      availableOperations?: Operation[]
    }
  } = {};

  public errors = {
    language: '',
    country: '',
    kType: '',
    garages: '',
    mapping: ''
  };

  public isDrain = false;
  public isRevision = false;

  constructor(private prestationsService: PrestationsService,
              private operationsService: OperationsService,
              private estimatesService: EstimatesService,
              private estimatesrequestService: EstimatesrequestService,
              private i18nService: I18nService,
              private loginService: LoginService) {
  }

  ngOnInit() {
    this.loginService.init('/quotations');
    this.getPrestations(0);
    this.getOperations();
    this.i18nService.getLang().subscribe(
      (lang: string) => {
        this.lang = lang.charAt(0).toUpperCase() + lang.substr(1).toLowerCase();
      }
    );
  }

  searchChanged() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.getPrestations(0);
      this.searchTimeout = undefined;
    }, 300);
  }

  getNextPage() {
    if (!this.loadingPage && this.totalItem && this.originalPrestations.length < this.totalItem) {
      this.getPrestations(this.lastPage + 1);
    }
  }

  getPrestations(page?: number) {
    this.loadingPage = true;
    this.prestationsService.getPrestations(this.searchText, page, true).subscribe(
      (result: { total: number, prestations: Array<Prestation> }) => {
        this.totalItem = result.total;
        this.lastPage = page;
        if (result.prestations && result.prestations.length > 0) {
          this.setPrestations(result.prestations, page);
        }
        this.loadingPage = false;
      }
    );
  }

  setPrestations(prestations: Array<Prestation>, page) {
    const formattedPrestation: Array<Prestation> = this.setPrestationsMeta(prestations);

    if (page === 0) {
      this.originalPrestations = prestations;
      this.form.serviceList = formattedPrestation;

    } else {
      this.originalPrestations = [...this.originalPrestations, ...prestations];
      this.form.serviceList = [...this.form.serviceList, ...formattedPrestation];
    }
  }


  setPrestationsMeta(prestations: Array<Prestation>) {
    const result: Array<Prestation> = [];
    prestations.forEach((prestation) => {
      if (!this.prestationsMetaInfo[prestation.id]) {
        this.prestationsMetaInfo[prestation.id] = {
          opened: false,
          enabled: false,
          reference: prestation.reference,
          availableOperations: undefined,
          operations: undefined,
        };
      }
      if (prestation.operations && prestation.operations.length > 0) {
        this.prestationsMetaInfo[prestation.id].availableOperations = [...prestation.operations];
        result.push({...prestation});
      }
    });
    result.forEach((prestation) => {
      if (this.prestationsMetaInfo[prestation.id]) {
        if (!this.prestationsMetaInfo[prestation.id].opened) {
          this.prestationsMetaInfo[prestation.id].opened = false;
        }
        if (this.prestationsMetaInfo[prestation.id].operations) {
          prestation.operations = this.prestationsMetaInfo[prestation.id].operations;
        } else {
          prestation.operations = [];
        }
      }
    });
    return result;
  }

  getOperations(searchText?: string) {
    this.operationsService.getOperations(searchText, 0, false).subscribe(
      (results: { total: number, operations: Array<Operation> }) => {
        this.setOperations(results.operations);
      }
    );
  }

  setOperations(operations: Array<Operation>) {
    this.operations = [];
    operations.forEach((operation) => {
      if (!operation.prestationId) {
        this.operations.push(operation);
      }
    });
  }

  togglePrestation(event, prestationId) {
    event.stopPropagation();
    // tslint:disable-next-line:max-line-length
    if (this.prestationsMetaInfo[prestationId] && this.prestationsMetaInfo[prestationId].availableOperations && this.prestationsMetaInfo[prestationId].availableOperations.length > 0) {
      this.prestationsMetaInfo[prestationId].opened = !this.prestationsMetaInfo[prestationId].opened;
    }
  }

  selectPrestation(event, prestationId) {
    event.stopPropagation();
    if (this.prestationsMetaInfo[prestationId]) {
      this.prestationsMetaInfo[prestationId].enabled = !this.prestationsMetaInfo[prestationId].enabled;
      this.form.serviceList.find(prestation => prestation.id === prestationId).enabled = this.prestationsMetaInfo[prestationId].enabled;
      this.form.serviceList.find(prestation => prestation.id === prestationId).operations = [];
    }
  }

  selectOperation(prestation: Prestation, operation: Operation, isEnabledPrestation) {
    if (isEnabledPrestation) {
      if (this.prestationsMetaInfo[prestation.id] && operation) {
        const index = prestation.operations.findIndex((op) => op.id === operation.id);
        if (index > -1) {
          this.removeOperation(prestation, index, operation);
        } else {
          this.addOperation(prestation, operation);
        }
      }
    }
  }

  addOperation(prestation, operation) {
    prestation.operations.push(operation);
    if (this.prestationsMetaInfo[prestation.id].operations) {
      this.prestationsMetaInfo[prestation.id].operations.push(operation);
    } else {
      this.prestationsMetaInfo[prestation.id].operations = [operation];
    }
  }

  removeOperation(prestation, index, operation) {
    prestation.operations.splice(index, 1);
    if (this.prestationsMetaInfo[prestation.id].operations) {
      const indexMeta = this.prestationsMetaInfo[prestation.id].operations.findIndex((op) => op.id === operation.id);
      if (indexMeta > -1) {
        this.prestationsMetaInfo[prestation.id].operations.splice(indexMeta, 1);
      }
    } else {
      this.prestationsMetaInfo[prestation.id].operations = [];
    }
  }

  isOperationChecked(prestation: Prestation, operation: Operation) {
    if (prestation.operations && prestation.operations.length > 0) {
      const index = prestation.operations.findIndex((op) => op.id === operation.id);
      return index > -1;
    } else {
      return false;
    }
  }

  addGarage() {
    this.form.idGarage.push(this.newGarage);
    this.newGarage = '';
  }

  removeGarage(index: number) {
    this.form.idGarage.splice(index, 1);
  }

  validateForm() {
    this.fetchingQuotation = true;
    if (this.isFormValid()) {
      if (this.form.kType &&
        this.form.idGarage &&
        this.form.idGarage.length > 0 &&
        this.form.language &&
        this.form.country) {
        this.form = this.formatForm();
        this.previousForm = this.estimatesService.mapFromForm(this.form);
        this.previousForm.country = this.form.country;
        this.previousForm.language = this.form.language;
        this.previousForm.idGarages = this.form.idGarage;
        this.previousForm.kTyp = this.form.kType;
        this.previousForm.isDrain = this.isDrain;
        this.previousForm.isRevision = this.isRevision;
        this.estimatesState = 'display';


        if (!this.isRevision) {
          this.getEstimates();
          this.getEstimatesRequest();
        }
        this.fetchingQuotation = false;

      }
    }
  }

  isFormValid() {
    this.errors = {
      language: '',
      country: '',
      kType: '',
      garages: '',
      mapping: ''
    };

    let isValid = true;
    if (!this.form.language) {
      this.errors.language = 'APP.ESTIMATES.ERRORS.MISSING_LANGUAGE';
      isValid = false;
    }
    if (!this.form.country) {
      this.errors.language = 'APP.ESTIMATES.ERRORS.MISSING_COUNTRY';
      isValid = false;
    }
    if (!this.form.kType) {
      this.errors.language = 'APP.ESTIMATES.ERRORS.MISSING_KTYPES';
      isValid = false;
    }
    if (!this.form.idGarage || this.form.idGarage.length < 1) {
      this.errors.language = 'APP.ESTIMATES.ERRORS.MISSING_GARAGES';
      isValid = false;
    }
    let prestation = false;

    let isUnvalidPrestation = false;

    for (const obj of this.form.serviceList) {
      if (obj.enabled && obj.operations) {
        if (obj.operations.length === 0) {
          isUnvalidPrestation = true;
        } else {
          prestation = true;
        }
      }
      if (!obj.enabled && obj.operations) {
        if (obj.operations.length > 0) {
          isUnvalidPrestation = true;
        }
      }
    }

    if (!prestation && !this.isRevision && !this.isDrain || isUnvalidPrestation) {
      this.errors.mapping = 'APP.ESTIMATES.ERRORS.MISSING_MAPPING';
      isValid = false;
    }
    return isValid;
  }

  clearForm() {
    this.form = {
      language: '',
      country: '',
      kType: undefined,
      idGarage: [],
      serviceList: []
    };
    this.estimatesState = 'form';
    this.hasDisplay = false;
    this.hasRequestDisplay = false;
    this.selectedChoices = undefined;
    this.infos = undefined;
    this.requestdisplay = undefined;
    this.displayNotFound = false;
    this.prestationsMetaInfo = {};
    this.searchText = '';
    this.newGarage = '';
    this.getPrestations(0);
  }

  returnToForm() {
    this.estimatesState = 'form';
    this.hasDisplay = false;
    this.hasRequestDisplay = false;
    this.infos = undefined;
    this.requestdisplay = undefined;
    this.displayNotFound = false;
    this.prestationsMetaInfo = {};
    this.searchText = '';
    this.newGarage = '';
    this.getPrestations(0);
  }

  formatForm() {
    const form = {
      language: this.form.language,
      country: this.form.country,
      kType: this.form.kType,
      idGarage: [...this.form.idGarage],
      serviceList: [],
      vidange : this.isDrain
    };
    Object.keys(this.prestationsMetaInfo).forEach((key) => {
      if (this.prestationsMetaInfo[key] &&
        this.prestationsMetaInfo[key].enabled &&
        this.prestationsMetaInfo[key].operations &&
        this.prestationsMetaInfo[key].operations.length > 0) {
        form.serviceList.push({
          id: key,
          reference: this.prestationsMetaInfo[key].reference,
          operations: [...this.prestationsMetaInfo[key].operations]
        });
      }
    });
    return form;
  }

  getEstimates() {

    const form = this.formatForm();

    this.estimatesService.getEstimates(form, this.selectedChoices).subscribe(
      (data) => {
        this.infos = data;
        this.hasDisplay = true;
        this.fetchingQuotation = false;
        this.hasRequestDisplay = true;
      },
      error => {
        this.displayNotFound = true;
        this.fetchingQuotation = false;

      }
    );
  }

  showResult(data) {
    this.estimatesState = 'result';
    if(data.infos !== undefined){
      this.infos = data.infos;
    }else{
        this.displayNotFound = true;
        this.fetchingQuotation = false;
    }
    this.requestdisplay = data.request;
    this.isRevision = false;
    this.isDrain = false;
    this.hasRequestDisplay = true;
  }

  getEstimatesRequest() {

    const form = this.formatForm();
    this.estimatesState = 'result';
    this.estimatesrequestService.getEstimatesRequest(form, this.selectedChoices).subscribe(
      (data) => {
        this.requestdisplay = data;
        this.hasRequestDisplay = true;

      },
      error => {
        this.displayNotFound = true;
      }
    );
  }

}
