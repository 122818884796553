import {Component, OnInit} from '@angular/core';
import {GridDescriptor} from '../core/models/grid/grid-descriptor';
import {Country} from '../core/models/country';
import {CountriesService} from '../core/services/countries.service';
import {Recommendation} from '../core/models/recommendation';
import {RecommendationService} from './services/recommendation.service';
import {ToastService} from '../core/services/toast.service';
import {ToastDetailledErrorService} from '../core/services/toast-detailled-error.service';
import {I18nService} from '../core/services/i18n.service';
import {ModalDescriptor} from '../core/models/modal/modal-descriptor';
import {Observable} from 'rxjs';
import {UserService} from '../core/services/user.service';

@Component({
  selector: 'app-standard-grid',
  templateUrl: './standard-grid.component.html',
  styleUrls: ['./standard-grid.component.scss']
})
export class StandardGridComponent implements OnInit {
  public originalRecommendations: Array<Recommendation> = [];
  public isModifOK = false;
  public recommendations: Array<{
    id: number,
    reference: string,
    labelFr: string,
    labelEn: string,
    labelEs: string,
    prices: {
      [key: string]: number
    }
  }> = [];
  public descriptor: GridDescriptor;

  private modalDescriptorModel: ModalDescriptor = {
    width: 450,
    height: 200,
    title: '',
    titleParameters: {},
    message: '',
    messageParameters: {},
    showModal: false,
    actions: [],
    clickOutsideBehavior: undefined,
    clickOutsideBehaviorParameters: []
  };

  public modalDescriptor: ModalDescriptor = JSON.parse(JSON.stringify(this.modalDescriptorModel));

  public countries: Array<Country> = [];
  public selectedCountry: Country;

  public searchText = '';
  private searchTimeout: any;

  public totalItem = 0;
  public loadingPage = false;
  private lastPage = 0;

  private lang = 'En';

  constructor(private countriesService: CountriesService,
              private i18nService: I18nService,
              private toastService: ToastService,
              private recommendationService: RecommendationService,
              private toastDetailledErrorService: ToastDetailledErrorService,
              public userService: UserService) {
  }

  ngOnInit() {
    this.getCountries();
    this.i18nService.getLang().subscribe(
      (lang: string) => {
        this.lang = lang.charAt(0).toUpperCase() + lang.substr(1).toLowerCase();
        if (this.descriptor && this.descriptor.columns && this.descriptor.columns[1]) {
          this.descriptor.columns[1].value = `label${this.lang}`;
        }
      }
    );
  }

  searchChanged() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.getRecommendations(this.selectedCountry, 0);
      this.searchTimeout = undefined;
    }, 300);
  }

  getNextPage() {
    if (!this.loadingPage && this.totalItem && this.recommendations.length < this.totalItem) {
      this.getRecommendations(this.selectedCountry, this.lastPage + 1);
    }
  }

  getCountries(searchText?: string) {
    this.countriesService.getCountries(searchText).subscribe(
      (results: { total: number, countries: Array<Country> }) => {
        if (results.countries && results.countries.length > 0) {
          this.setCountries(results.countries);
        }
      }
    );
  }

  setCountries(countries: Array<Country>) {
    this.countries = countries;
    if (this.countriesService.countryId) {
      const country = this.countries.find((c: Country) => c.id === this.countriesService.countryId);
      if (country) {
        this.onCountryChange(country);
      }
    }
  }

  onCountryChange(country: Country) {
    this.countriesService.getCountry(country.id).subscribe(
      (fullCountry: Country) => {
        this.selectedCountry = fullCountry;
        if (this.countriesService.countryId !== country.id) {
          this.countriesService.setCountry(fullCountry.id);
        }
        this.getRecommendations(fullCountry, 0);
      }
    );
  }

  getRecommendations(country: Country, page?: number) {
    this.loadingPage = true;
    this.recommendationService.getRecommendations(this.selectedCountry, this.searchText, page).subscribe(
      (result: { total: number, recommendations: Array<Recommendation> }) => {
        this.totalItem = result.total;
        this.lastPage = page;
        if (result.recommendations) {
          if (page === 0) {
            this.recommendations = [];
            this.setRecommendations(result.recommendations);
            this.setDescriptor();
          } else {
            this.setRecommendations([...this.originalRecommendations, ...result.recommendations]);
          }
        }
        this.loadingPage = false;
      }
    );
  }

  setDescriptor() {
    this.descriptor = {
      columns: [
        {
          id: 1,
          label: 'APP.STANDARD_GRID.ID',
          labelParameters: {},
          value: 'reference',
          type: 'text',
          weight: '2'
        },
        {
          id: 2,
          label: 'APP.STANDARD_GRID.LABEL',
          labelParameters: {},
          value: `label${this.lang}`,
          type: 'text',
          weight: '3'

        }
      ],
      actions: [],
      newLine: false
    };

    this.selectedCountry.listArea.forEach((area, index) => {
      this.descriptor.columns.push({
        id: index + 3,
        label: 'APP.STANDARD_GRID.PRICE_ZONE',
        labelParameters: {area: area.label},
        value: `prices.${area.label}`,
        type: 'number',
        weight: '1',
        // suffix: this.selectedCountry.currency
      });
    });
  }

  setRecommendations(recommendations: Array<Recommendation>) {
    this.originalRecommendations = recommendations;
    recommendations.forEach((recommendation) => {
      if (recommendation && recommendation.reference) {
        this.setRecommendation(recommendation);
      } else {
        this.totalItem = this.totalItem - 1;
      }
    });
  }

  setRecommendation(recommendation: Recommendation) {
    const recommendationPrices = {};
    recommendation.prices.forEach((price) => {
      recommendationPrices[price.zone] = price.price;
    });

    const index = this.recommendations.findIndex((r) => r.reference === recommendation.reference);
    if (index > -1) {
      this.recommendations[index] = {
        id: recommendation.id,
        reference: recommendation.reference,
        labelFr: recommendation.labelFr,
        labelEn: recommendation.labelEn,
        labelEs: recommendation.labelEs,
        prices: recommendationPrices
      };
    } else {
      this.recommendations.push({
        id: recommendation.id,
        reference: recommendation.reference,
        labelFr: recommendation.labelFr,
        labelEn: recommendation.labelEn,
        labelEs: recommendation.labelEs,
        prices: recommendationPrices
      });
    }
  }

  importGrid(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.recommendationService.import(file, this.selectedCountry).subscribe(
        (data: any) => {
          this.toastDetailledErrorService.resetToastDetailledError();
          this.toastService.setToast('APP.STANDARD_GRID.UPLOAD_SUCCESSFUL', 'success');
          if (data.errorMessages !== null && data.errorMessages.length > 0) {
            this.toastDetailledErrorService.setToastDetailledError('ERRORS.200', 'error', data.errorMessages);
          }
          this.countriesService.getCountry(this.selectedCountry.id).subscribe(
            (fullCountry: Country) => {
              this.selectedCountry = fullCountry;
              if (this.countriesService.countryId !== this.selectedCountry.id) {
                this.countriesService.setCountry(fullCountry.id);
              }
              this.getRecommendations(fullCountry, 0);
            }
          );
        }
      );
    }
    event.target.value = null;
  }

  exportGrid() {
    this.recommendationService.export(this.selectedCountry).subscribe();
  }

  openRemoveModal() {
    return new Observable((observer) => {
      this.modalDescriptor.title = 'APP.STANDARD_GRID.REMOVE_MODAL_TITLE';
      this.modalDescriptor.message = 'APP.STANDARD_GRID.REMOVE_MODAL_MESSAGE';
      this.modalDescriptor.actions.push({
        icon: '',
        label: 'APP.ACTIONS.CANCEL',
        color: 'white',
        behavior: this.cancelModal.bind(this),
        behaviorParameters: [observer]
      });
      this.modalDescriptor.actions.push({
        icon: '',
        label: 'APP.ACTIONS.CONFIRM',
        color: 'red',
        behavior: this.confirmModal.bind(this),
        behaviorParameters: [observer]
      });
      this.modalDescriptor.clickOutsideBehavior = this.cancelModal.bind(this);
      this.modalDescriptor.clickOutsideBehaviorParameters = [observer];
      this.modalDescriptor.showModal = true;
    });
  }

  openConfirmationPopUp() {
    this.openRemoveModal().subscribe(confirmation => {
      if (confirmation) {
        this.recommendationService.deleteRecommendations(this.selectedCountry)
          .subscribe(
            () => this.getRecommendations(this.selectedCountry, 0),
            error => {
              if (error.status === 412) {
                this.toastService.setToast(error.error.message, 'error');
              }
            }
          );
      }
    });
  }

  confirmModal(observer) {
    observer.next(true);
    observer.complete();
    this.closeModal();
  }

  cancelModal(observer) {
    observer.next(false);
    observer.complete();
    this.closeModal();
  }

  closeModal() {
    this.modalDescriptor = JSON.parse(JSON.stringify(this.modalDescriptorModel));
  }
}
