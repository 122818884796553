import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LoginService } from '../services/login.service';
import { I18nService } from '../services/i18n.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent {

  @Input() sideMenuShown: boolean;

  @Output() sideMenuShownChange = new EventEmitter<boolean>();

  public langs: Array<{code: string}> = [
    {code: 'fr'}, {code: 'en'}, {code: 'es'}
  ];

  constructor(private loginService: LoginService,
              private i18nService: I18nService,
              public userService: UserService) {}

  onSideMenuShownChange() {
    this.sideMenuShownChange.emit(!this.sideMenuShown);
  }

  logout() {
    this.loginService.logout();
  }

  changeLang(code: string) {
    this.i18nService.setLocale(code);
  }
}
