import { NgModule } from '@angular/core';

import { CoreModule } from '../core/core.module';
import { PromotionGridComponent } from './promotion-grid.component';

@NgModule({
  declarations: [
    PromotionGridComponent
  ],
  imports: [
    CoreModule,
  ],
  providers: []
})
export class PromotionGridModule { }
