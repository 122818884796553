import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppRoutingModule } from '../app-routing.module';

import { TopbarComponent } from './topbar/topbar.component';
import { LoginService } from './services/login.service';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthHeaderInterceptor } from './services/auth-header.interceptor';
import { GenericGridComponent } from './generic-grid/generic-grid.component';
import { GenericGridFieldsComponent } from './generic-grid/generic-grid-fields/generic-grid-fields.component';
import { I18nService } from './services/i18n.service';
import { UserService } from './services/user.service';
import { ClickOutsideDirective } from './click-outside/click-outside.directive';
import { ClickOutsideInteractiveDirective } from './click-outside/click-outside-interactive.directive';
import { SelectComponent } from './select/select.component';
import { CountriesService } from './services/countries.service';
import { CustomModalComponent } from './custom-modal/custom-modal.component';
import { DatePickerService } from './date-picker/date-picker.service';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { CalendarComponent } from './calendar/calendar.component';
import { ToastComponent } from './toast/toast.component';
import { ToastService } from './services/toast.service';
import { ToastDetailledErrorService } from './services/toast-detailled-error.service';
import { ToastDetailledErrorComponent } from './toast-detailled-error/toast-detailled-error.component';
import { HttpErrorInterceptor } from './services/http-error-interceptor';
import { PrestationsService } from './services/prestations.service';
import { UtilsService } from './services/utils.service';
import { InfiniteScrollDirective } from './infinite-scroll/infinite-scroll.directive';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { MatTabsModule } from '@angular/material/tabs';
import { CookieService } from 'ngx-cookie-service';
import { NgxMaskModule } from 'ngx-mask';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    TopbarComponent,
    SideMenuComponent,
    GenericGridComponent,
    GenericGridFieldsComponent,
    SelectComponent,
    CalendarComponent,
    DatePickerComponent,
    CustomModalComponent,
    ToastComponent,
    ToastDetailledErrorComponent,
    CheckboxComponent,
    InfiniteScrollDirective,
    ClickOutsideDirective,
    ClickOutsideInteractiveDirective
  ],
  imports: [
    FormsModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    AppRoutingModule
  ],
  exports: [
    FormsModule,
    BrowserAnimationsModule,
    BrowserModule,
    MatTabsModule,
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    NgxMaskModule,

    TopbarComponent,
    SideMenuComponent,
    GenericGridComponent,
    GenericGridFieldsComponent,
    SelectComponent,
    CalendarComponent,
    DatePickerComponent,
    CustomModalComponent,
    ToastComponent,
    ToastDetailledErrorComponent,
    CheckboxComponent,
    InfiniteScrollDirective,
    ClickOutsideDirective,
    ClickOutsideInteractiveDirective,

    AppRoutingModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    LoginService,
    UserService,
    AuthGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHeaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },

    TranslateService,
    I18nService,
    DatePickerService,
    CountriesService,
    PrestationsService,
    UtilsService,
    ToastService,
    ToastDetailledErrorService,
    CookieService
  ]
})
export class CoreModule {
}
