import { Component, OnInit } from '@angular/core';
import { RulesService } from './services/rules.service';
import { Rule } from '../../core/models/rule';
import { GridDescriptor } from '../../core/models/grid/grid-descriptor';
import { Observable } from 'rxjs';
import { ModalDescriptor } from '../../core/models/modal/modal-descriptor';
import { Country } from '../../core/models/country';
import { CountriesService } from '../../core/services/countries.service';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss']
})
export class RulesComponent implements OnInit {

  public searchText: string;
  public isModifOK = false;

  public rules: Array<Rule> = [];
  public countries: Array<Country> = [];

  public  loadingPage = false;
  private lastPage = 0;
  private totalItem = 0;
  private searchTimeout: any;


  public selectedCountry: Country;

  public gridDescriptor: GridDescriptor;
  public lineStructure: Rule = {
    country: undefined,
    thresholdPrice: undefined
  };

  private modalDescriptorModel: ModalDescriptor = {
    width: 450,
    height: 200,
    title: '',
    titleParameters: {},
    message: '',
    messageParameters: {},
    showModal: false,
    actions: [],
    clickOutsideBehavior: undefined,
    clickOutsideBehaviorParameters: []
  };

  public modalDescriptor: ModalDescriptor = JSON.parse(JSON.stringify(this.modalDescriptorModel));

  constructor(private rulesService: RulesService, private countriesService: CountriesService) {}

  ngOnInit() {
    this.getCountries();
  }

  searchChanged() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.getRules(this.selectedCountry, 0);
      this.searchTimeout = undefined;
    }, 300);
  }

  getNextPage() {
    if (!this.loadingPage && this.totalItem && this.rules.length < this.totalItem) {
      this.getRules(this.selectedCountry, this.lastPage + 1);
    }
  }

  getRules(country: Country, page?: number) {
    this.loadingPage = true;
    this.rulesService.getRules(this.selectedCountry, this.searchText, page).subscribe(
      (result: {total: number, rules: Array<Rule>}) => {
        this.totalItem = result.total;
        this.lastPage = page;
        if (result.rules) {
          if (page === 0) {
            this.setRules(result.rules);
          } else {
            this.setRules([...this.rules, ...result.rules]);
          }
        }
        this.loadingPage = false;
      }
    );
  }

  setRules(rules: Array<Rule>) {
    this.rules = rules;
    if (this.rules && this.rules.length > 0) {
      this.gridDescriptor.newLine = false;
    } else {
      this.gridDescriptor.newLine = true;
    }
  }

  getCountries(searchText?: string) {
    this.countriesService.getCountries(searchText).subscribe(
      (result: {total: number, countries: Array<Country>}) => {
        if (result.countries && result.countries.length > 0) {
          this.setCountries(result.countries);
        }
      }
    );
  }

  setCountries(countries: Array<Country>) {
    this.countries = countries;
    this.setDescriptor();
    if (this.countriesService.countryId) {
      this.selectedCountry = this.countries.find((country: Country) => country.id === this.countriesService.countryId);
      if (this.selectedCountry) {
        this.getRules(this.selectedCountry, 0);
      }
    }
  }

  onCountryChange(country: Country) {
    this.selectedCountry = country;
    this.countriesService.setCountry(country.id);
    this.getRules(country, 0);
  }

  setDescriptor() {
    this.gridDescriptor = {
      columns: [
        // {
        //   id: 1,
        //   label: 'APP.RULES.COUNTRY',
        //   labelParameters: {},
        //   value: 'country',
        //   display: 'country.label',
        //   disabled: true,
        //   type: 'select',
        //   weight: '2',
        //   selectDescriptor: {
        //     options: this.countries,
        //     model: {name: 'label', value: ''},
        //     searchChangeBehavior: this.getCountries.bind(this)
        //   }
        // },
        {
          id: 2,
          label: 'APP.RULES.PRICE',
          labelParameters: {},
          value: 'thresholdPrice',
          display: 'APP.RULES.WORDING',
          displayParams: [{key: 'threshold', value: 'thresholdPrice'}, {key: 'currency', value: 'country.currency'}],
          type: 'number',
          subType: 'i18n',
          required: true,
          weight: '8'
        }
      ],
      actions: [
        {
          label: 'remove',
          tooltip: 'APP.RULES.REMOVE_LINE_TOOLTIP',
          icon: 'fa-trash error-button',
          behavior: this.removeRule.bind(this),
          confirmation: true,
          parameters: []
        }
      ],
      actionWeight: '1',
      newLine: true
    };
  }

  addLine(newLine: {thresholdPrice: number, country: Country, id?: number}) {
    if (newLine.id) {
      this.rulesService.setLine(this.selectedCountry, newLine).subscribe(
        (rule: Rule) => {
          const index = this.rules.findIndex((d) => d.id === rule.id);
          if (index > -1) {
            this.rules[index] = rule;
          } else {
            this.rules.push(rule);
          }
          this.gridDescriptor.newLine = false;
          this.isModifOK = !this.isModifOK;
        }
      );
    } else {
      this.rulesService.addLine(this.selectedCountry, newLine).subscribe(
        (newRule: Rule) => {
          this.rules.push(newRule);
          this.gridDescriptor.newLine = false;
          this.isModifOK = !this.isModifOK;
        }
      );
    }
  }

  closeModal() {
    this.modalDescriptor = JSON.parse(JSON.stringify(this.modalDescriptorModel));
  }

  confirmModal(observer) {
    observer.next(true);
    observer.complete();
    this.closeModal();
  }

  cancelModal(observer) {
    observer.next(false);
    observer.complete();
    this.closeModal();
  }

  openRemoveModal(rule: Rule) {
    return new Observable((observer) => {
      this.modalDescriptor.title = 'APP.PRESTATIONS_GRID.REMOVE_MODAL_TITLE';
      this.modalDescriptor.message = 'APP.PRESTATIONS_GRID.REMOVE_MODAL_MESSAGE';
      this.modalDescriptor.actions.push({
        icon: '',
        label: 'APP.ACTIONS.CANCEL',
        color: 'white',
        behavior: this.cancelModal.bind(this),
        behaviorParameters: [observer]
      });
      this.modalDescriptor.actions.push({
        icon: '',
        label: 'APP.ACTIONS.CONFIRM',
        color: 'red',
        behavior: this.confirmModal.bind(this),
        behaviorParameters: [observer]
      });
      this.modalDescriptor.clickOutsideBehavior = this.cancelModal.bind(this);
      this.modalDescriptor.clickOutsideBehaviorParameters = [observer];
      this.modalDescriptor.showModal = true;
    });
  }

  removeRule(ruleId: number) {
    const index = this.rules.findIndex((rule) => rule.id === ruleId);
    if (index > -1) {
      this.openRemoveModal(this.rules[index]).subscribe(
        (success: boolean) => {
          if (success) {
            this.rulesService.removeRule(ruleId).subscribe(
              (data) => {
                this.getRules(this.selectedCountry, 0);
              }
            );
          }
        }
      );
    }
  }
}
