import {Injectable} from '@angular/core';
import {User} from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public user: User;

  constructor() {
    if (!this.user) {
      const user = localStorage.getItem('user');
      this.user = JSON.parse(user);
    }
  }

  setUser(user: User) {
    this.user = user;
    localStorage.setItem('user', JSON.stringify(this.user));
  }
}
