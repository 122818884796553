import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '../../../core/services/utils.service';
import { map } from 'rxjs/operators';
import { Operation } from '../../../core/models/operation';
import { Genart } from '../../../core/models/genart';
import { Country } from 'src/app/core/models/country';

const OIL_PATH = '/oilcontainer';


@Injectable({
  providedIn: 'root'
})
export class OilscontainerService {

  constructor(private http: HttpClient, private utilsService: UtilsService) { }

  getOilByCountry(country: Country) {
    const url = `${environment.baseUrl}${OIL_PATH}/country/${country.id}`;
    return this.http.get(url);
  }

  updateOilList(body: any) {
    const url = `${environment.baseUrl}${OIL_PATH}/update`;
    return this.http.put(url, body);
  }
}
