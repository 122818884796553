declare var require: any;
import { Component, OnInit } from '@angular/core';
import { Promotion } from '../core/models/promotion';
import { GridDescriptor } from '../core/models/grid/grid-descriptor';
import { Country } from '../core/models/country';
import { PromotionService } from './services/promotion.service';
import { CountriesService } from '../core/services/countries.service';
import { Observable } from 'rxjs';
import { ModalDescriptor } from '../core/models/modal/modal-descriptor';
import { Recommendation } from '../core/models/recommendation';
import { RecommendationService } from '../standard-grid/services/recommendation.service';
import { Moment } from 'moment';
import { I18nService } from '../core/services/i18n.service';
import { ToastService } from '../core/services/toast.service';
const moment = require('moment');

@Component({
  selector: 'app-promotion-grid',
  templateUrl: './promotion-grid.component.html',
  styleUrls: ['./promotion-grid.component.scss']
})
export class PromotionGridComponent implements OnInit {
  public originalPromotions: Array<Promotion> = [];

  public isModifOK = false;
  public promotions: Array<{
    id: number,
    ref: string,
    recommendation: Recommendation,
    startDate: Moment,
    endDate: Moment,
    prices: {
      [key: number]: number
    }
  }> = [];
  public descriptor: GridDescriptor;

  public newLineStructure: {
    id: number,
    ref: string
    recommendation: Recommendation,
    prices: {
      [key: number]: number
    },
    startDate: Date,
    endDate: Date
  } = {
    id: undefined,
    ref: '',
    recommendation: undefined,
    prices: {},
    startDate: undefined,
    endDate: undefined
  };

  public countries: Array<Country> = [];
  public selectedCountry: Country;

  private modalDescriptorModel: ModalDescriptor = {
    width: 450,
    height: 200,
    title: '',
    titleParameters: {},
    message: '',
    messageParameters: {},
    showModal: false,
    actions: [],
    clickOutsideBehavior: undefined,
    clickOutsideBehaviorParameters: []
  };

  public modalDescriptor: ModalDescriptor = JSON.parse(JSON.stringify(this.modalDescriptorModel));

  public searchText = '';
  private searchTimeout: any;

  public totalItem = 0;
  public loadingPage = false;

  public recommendations: Array<Recommendation> = [];
  private lastPage = 0;

  private lang = 'En';

  constructor(private countriesService: CountriesService,
    private recommendationsService: RecommendationService,
    private i18nService: I18nService,
    private promotionService: PromotionService,
    private toastService: ToastService) { }

  ngOnInit() {
    this.getCountries();
    this.i18nService.getLang().subscribe(
      (lang: string) => {
        this.lang = lang.charAt(0).toUpperCase() + lang.substr(1).toLowerCase();
        if (this.descriptor && this.descriptor.columns && this.descriptor.columns[1]) {
          this.descriptor.columns[1].display = `recommendation.label${this.lang}`;
          if (this.descriptor.columns[1].selectDescriptor && this.descriptor.columns[1].selectDescriptor.model) {
            this.descriptor.columns[1].selectDescriptor.model.name = `label${this.lang}`;
          }
        }
      }
    );
  }

  searchChanged() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.getPromotions(this.selectedCountry, 0);
      this.searchTimeout = undefined;
    }, 300);
  }

  getNextPage() {
    if (!this.loadingPage && this.totalItem && this.promotions.length < this.totalItem) {
      this.getPromotions(this.selectedCountry, this.lastPage + 1);
    }
  }

  getCountries(searchText?: string) {
    this.countriesService.getCountries(searchText).subscribe(
      (results: { total: number, countries: Array<Country> }) => {
        if (results.countries && results.countries.length > 0) {
          this.setCountries(results.countries);
        }
      }
    );
  }

  setCountries(countries: Array<Country>) {
    this.countries = countries;
    if (this.countriesService.countryId) {
      const country = this.countries.find((c: Country) => c.id === this.countriesService.countryId);
      if (country) {
        this.onCountryChange(country);
      }
    }
  }

  onCountryChange(country: Country) {
    this.countriesService.getCountry(country.id).subscribe(
      (fullCountry: Country) => {
        this.selectedCountry = fullCountry;
        if (this.countriesService.countryId !== country.id) {
          this.countriesService.setCountry(fullCountry.id);
        }
        this.getRecommendations();
        this.getPromotions(fullCountry, 0);
      }
    );
  }

  getRecommendations(searchText?: string) {
    this.recommendationsService.getRecommendations(this.selectedCountry, searchText, 0, 40).subscribe(
      (results: { total: number, recommendations: Array<Recommendation> }) => {
        if (results.recommendations && results.recommendations.length > 0) {
          this.setRecommendations(results.recommendations);
        } else {
          this.recommendations = [];
        }
      }
    );
  }

  setRecommendations(recommendations: Array<Recommendation>) {
    this.recommendations = recommendations;
    if (!this.descriptor) {
      this.setDescriptor();
    } else {
      this.descriptor.columns[1].selectDescriptor.options = this.recommendations;
    }
  }

  getPromotions(country: Country, page?: number) {
    this.loadingPage = true;
    this.promotionService.getPromotions(this.selectedCountry, this.searchText, page).subscribe(
      (result: { total: number, promotions: Array<Promotion> }) => {
        this.totalItem = result.total;
        this.lastPage = page;
        if (result.promotions) {
          if (page === 0) {
            this.promotions = [];
            this.setPromotions(result.promotions);
            this.setDescriptor();
          } else {
            this.setPromotions([...this.originalPromotions, ...result.promotions]);
          }
        }
        this.loadingPage = false;
      }
    );
  }

  setPromotions(promotions: Array<Promotion>) {
    this.originalPromotions = promotions;
    promotions.forEach((promotion) => {
      if (promotion.recommendation && promotion.recommendation.reference && promotion.prices.length > 0) {
        this.setPromotion(promotion);
      } else {
        this.totalItem = this.totalItem - 1;
      }
    });
  }

  setDescriptor() {
    this.descriptor = {
      columns: [
        {
          id: 4,
          label: 'APP.PROMOTION_GRID.GROUPMENT_ID',
          labelParameters: {},
          value: 'id',
          type: 'internalId',
          disabled: true,
          weight: '1'
        },
        {
          id: 1,
          label: 'APP.DISCOUNT_GRID.FLAT_RATE',
          labelParameters: {},
          value: 'recommendation',
          display: `recommendation.label${this.lang}`,
          type: 'select',
          required: true,
          weight: '3',
          selectDescriptor: {
            options: this.recommendations,
            model: { name: `label${this.lang}`, value: '' },
            searchChangeBehavior: this.getRecommendations.bind(this)
          }
        },
        {
          id: 2,
          label: 'APP.PROMOTION_GRID.START',
          labelParameters: {},
          value: 'startDate',
          type: 'date',
          required: true,
          weight: '3',
          fieldValidator: {
            isValid: this.dateValidation,
            validationParams: [],
            linkedValidation: [3],
            message: 'APP.PROMOTION_GRID.ERRORS.START_DATE'
          }
        },
        {
          id: 3,
          label: 'APP.PROMOTION_GRID.END',
          labelParameters: {},
          value: 'endDate',
          type: 'date',
          required: true,
          weight: '3',
          fieldValidator: {
            isValid: this.dateValidation,
            validationParams: [],
            linkedValidation: [2],
            message: 'APP.PROMOTION_GRID.ERRORS.END_DATE'
          }
        }
      ],
      actions: [
        {
          label: 'remove',
          tooltip: 'APP.PROMOTION_GRID.REMOVE_LINE_TOOLTIP',
          icon: 'fa-trash error-button',
          behavior: this.removePromotion.bind(this),
          confirmation: true,
          parameters: []
        }
      ],
      actionWeight: '1',
      totalWeight: 11,
      newLine: true
    };

    this.selectedCountry.listArea.forEach((area, index) => {
      this.descriptor.columns.push({
        id: index + 5,
        label: 'APP.PROMOTION_GRID.PRICE_ZONE',
        labelParameters: { area: area.label },
        value: `prices.${area.id}`,
        type: 'number',
        weight: '1',
        fieldValidator: {
          isValid: this.priceValidation,
          validationParams: [],
          linkedValidation: [],
          message: 'APP.PROMOTION_GRID.ERRORS.PRICE'
        }
        // suffix: this.selectedCountry.currency
      });
      this.descriptor.totalWeight = this.descriptor.totalWeight + 1;
      this.newLineStructure.prices[area.id] = undefined;
    });
  }

  setPromotion(promotion: Promotion) {
    const promotionPrices = {};
    promotion.prices.forEach((price) => {
      const area = this.selectedCountry.listArea.find((a) => a.label === price.zone);
      promotionPrices[area.id] = price.price;
    });

    const index = this.promotions.findIndex((promo: any) => {
      return promo.id === promotion.id;
    });
    if (index > -1) {
      this.promotions[index] = {
        id: promotion.id,
        ref: promotion.ref,
        recommendation: promotion.recommendation,
        startDate: promotion.startDate,
        endDate: promotion.endDate,
        prices: promotionPrices
      };
    } else {
      this.promotions.push({
        id: promotion.id,
        ref: promotion.ref,
        recommendation: promotion.recommendation,
        startDate: promotion.startDate,
        endDate: promotion.endDate,
        prices: promotionPrices
      });
    }
  }

  addLine(newLine: {
    id: number,
    ref: string,
    recommendation: Recommendation,
    prices: { [key: string]: number },
    startDate: Date,
    endDate: Date
  }) {
    let promotionAlreadyExistsForThePeriod = false;
    let haveAtLeastOnPrice = false;

    newLine.startDate = moment(newLine.startDate).hours(12).toDate();
    newLine.endDate = moment(newLine.endDate).hours(12).toDate();
    promotionAlreadyExistsForThePeriod = this.promotions.some(promotion => {
        return (newLine.recommendation.reference === promotion.recommendation.reference && newLine.id !== promotion.id
          && promotion.startDate <= moment(newLine.endDate) && promotion.endDate >= moment(newLine.startDate));
    });

    Object.keys(newLine.prices).forEach((key) => {
      if (newLine.prices[key] !== undefined && newLine.prices[key] !== null && newLine.prices[key]) {
        haveAtLeastOnPrice = true;
      }
    });


    if (!haveAtLeastOnPrice) {
          this.toastService.setToast('APP.PROMOTION_GRID.ERRORS.NO_PRICE_AT_LEAST', 'error');
    } else if (promotionAlreadyExistsForThePeriod) {
      this.toastService.setToast('APP.PROMOTION_GRID.ERRORS.DUPLICATED_PROMOTION', 'error');
    } else {
      if (newLine.id) {
        const originalPromotion2 = this.originalPromotions.find((p) => p.id === newLine.id);
        const originalPromotion = this.originalPromotions.find((p) => p.id === newLine.id);
        this.promotionService.setLine(newLine, this.selectedCountry, originalPromotion).subscribe(
          (promotion: Promotion) => {
            this.getPromotions(this.selectedCountry, 0);
            this.isModifOK = !this.isModifOK;
          }
        );
      } else {
        this.promotionService.addLine(newLine, this.selectedCountry).subscribe(
          (newPromotion: Promotion) => {
            this.getPromotions(this.selectedCountry, 0);
            this.isModifOK = !this.isModifOK;
          }
        );
      }
    }
  }

  closeModal() {
    this.modalDescriptor = JSON.parse(JSON.stringify(this.modalDescriptorModel));
  }

  confirmModal(observer) {
    observer.next(true);
    observer.complete();
    this.closeModal();
  }

  cancelModal(observer) {
    observer.next(false);
    observer.complete();
    this.closeModal();
  }

  openRemoveModal(promotion: any) {
    return new Observable((observer) => {
      this.modalDescriptor.title = 'APP.PROMOTION_GRID.REMOVE_MODAL_TITLE';
      this.modalDescriptor.message = 'APP.PROMOTION_GRID.REMOVE_MODAL_MESSAGE';
      this.modalDescriptor.actions.push({
        icon: '',
        label: 'APP.ACTIONS.CANCEL',
        color: 'white',
        behavior: this.cancelModal.bind(this),
        behaviorParameters: [observer]
      });
      this.modalDescriptor.actions.push({
        icon: '',
        label: 'APP.ACTIONS.CONFIRM',
        color: 'red',
        behavior: this.confirmModal.bind(this),
        behaviorParameters: [observer]
      });
      this.modalDescriptor.clickOutsideBehavior = this.cancelModal.bind(this);
      this.modalDescriptor.clickOutsideBehaviorParameters = [observer];
      this.modalDescriptor.showModal = true;
    });
  }

  removePromotion(id: number) {
    const index = this.promotions.findIndex((promotion) => promotion.id === id);
    if (index > -1) {
      this.openRemoveModal(this.promotions[index]).subscribe(
        (success: boolean) => {
          if (success) {
            this.promotionService.removePromotion(id).subscribe(
              (data) => {
                this.getPromotions(this.selectedCountry, 0);
              }
            );
          }
        }
      );
    }
  }

  dateValidation(property: any,
    line: {
      id: string, recommendation: Recommendation,
      prices: { [key: string]: number }, startDate: Date, endDate: Date
    }): boolean {
    return !line.startDate || !line.endDate || new Date(line.startDate) <= new Date(line.endDate);
  }

  priceValidation(property: any,
    line: {
      id: string, recommendation: Recommendation,
      prices: { [key: string]: number }, startDate: Date, endDate: Date
    }): boolean {
    return !!+property || property === '';
  }
}
