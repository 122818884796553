import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OverhaulService {

  constructor(private http: HttpClient) {
  }

  getQuestions(data: { kType: string, Xcountry: string, XLanguage: string }) {
    const headers = new HttpHeaders({
      'X-Language': data.XLanguage ? data.XLanguage : '',
      'X-Country': data.Xcountry ? data.Xcountry : '',
      'X-Skip-Interceptor': ''
    });

    const url = `${environment.baseUrl.replace('/admin', '')}/aqt/diagnostic/gearboxQuestions?kType=${data.kType}`;
    return this.http.get(url, {headers: headers});
  }

  getFilters(data: { typeId: string, Xcountry: string, XLanguage: string }) {
    const headers = new HttpHeaders({
      'X-Language': data.XLanguage ? data.XLanguage : '',
      'X-Country': data.Xcountry ? data.Xcountry : '',
      'X-Skip-Interceptor': ''
    });

    const url = `${environment.baseUrl.replace('/admin', '')}/aqt/diagnostic/filterQuestions?typeId=${data.typeId}`;
    return this.http.get(url, {headers: headers});
  }

  /**
   * Get quote operations
   // tslint:disable-next-line:no-redundant-jsdoc
   * // @param data
   */
  getQuoteOperations(data: any): Observable<Object> {
    const headers = new HttpHeaders({});
     const body = {
      language: data.language,
      idGarage: data.idGarages !=null ?data.idGarages[0]:"" ,
      ktype: data.kTyp,
      km: data.km,
      registrationDate: data.registrationDate,
      // serviceList: data.serviceList,
      // operationRevision: data.operationRevision,
      revision: data.revision,
      // vidange: data.vidange
    };
    const url = `${environment.baseUrl.replace('/admin', '')}/aqt/maintenanceOperations`;
    // return this.http.get(url, {headers: headers});
     return this.http.post(url, body, {headers: headers});
  }


  postQuotation(data: any) {
    const headers = new HttpHeaders();
    /*const headers = new HttpHeaders({
      'X-Language': data.language,
      'X-Country': data.country,
      'X-Skip-Interceptor': ''
    });*/

    const body = {
      // language: data.language,
      idGarages: data.idGarages,
      kTyp: data.kTyp,
      // km: data.km,
      // registrationDate: data.registrationDate,
      serviceList: data.serviceList,
      operationRevision: data.operationRevision,
      // operationRevision : {
      //   principleWorkPositions: [data.primaryOperations],
      //   // principleOperations: data.primaryOperations,
      //   // principleOperations: data.selectPrincipleWork,
      //   // principleOperations: data.selectPrincipleWork,
      //   additionalOperations: []
      //   //  additionalOperations: data.selectAdditionalWork
      // },
      // operationRevision: data.operationRevision,
      // operationRevision: { data.additionalOperations && data.principalOperations },
      revision: true,
      vidange: data.vidange
    };
    const url = `${environment.baseUrl.replace('/admin', '')}/aqt/quote`;
    // return this.http.post('http://localhost:8080/aqt/maintenanceOperations', body, {headers}).toPromise();
        return this.http.post(url, body, {headers}).toPromise();
  }

  postQuotationRequest(data: any) {
    const headers = new HttpHeaders({
      'X-Language': data.language ? data.language : '',
      'X-Country': data.country ? data.country : '',
      // 'X-ktype': data.ktyp ? data.ktyp : '',
      // 'X-registrationDate': data.registrationDate ? data.registrationDate : '',
      'X-Skip-Interceptor': ''
    });

    const body = {...data};
    delete body.language;
    delete body.country;

    // return this.http.post(`${environment.baseUrl.replace('/admin', '')}/aqt/maintenanceOperations`, body, {headers}).toPromise();
 return this.http.post(`${environment.baseUrl.replace('/admin', '')}/aqt/quote/infos`, body, {headers}).toPromise();
    }



  lastNextKmAndMonth(data: {
    typeId: string,
    gearbox: string,
    possibleEquipments: any[],
    commissioningDate: string,
    km: string,
    country: string,
    language: string
  }) {
    const headers = new HttpHeaders({
      'X-Language': data.language ? data.language : '',
      'X-Country': data.country ? data.country : '',
      'X-Skip-Interceptor': ''
    });


    const body = {
      typeId: data.typeId,
      gearbox: data.gearbox,
      possibleEquipments: data.possibleEquipments,
      commissioningDate: data.commissioningDate,
      km: data.km
    };
    const url = `${environment.baseUrl.replace('/admin', '')}/aqt/diagnostic/lastNextKmAndMonth`;
    return this.http.post(url, body, {headers});
  }

  postKilometers(data: {
    typeId: string,
    gearbox: string,
    commissioningDate: string,
    km: string,
    mdServiceProposal: any,
    country: string,
    language: string
  }) {
    const headers = new HttpHeaders({
      'X-Language': data.language ? data.language : '',
      'X-Country': data.country ? data.country : '',
      'X-Skip-Interceptor': ''
    });

    const body = {
      gearbox: data.gearbox,
      commissioningDate: data.commissioningDate,
      typeId: data.typeId,
      km: data.km,
      mdServiceProposal: data.mdServiceProposal
    };

    const url = `${environment.baseUrl.replace('/admin', '')}/aqt/diagnostic/maintenancePlan`;
    return this.http.post(url, body, {headers});
  }
}
