import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Genart } from '../models/genart';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';

const GENART_PATH = '/genart/albalogic';

@Injectable({
  providedIn: 'root'
})
export class GenartService {

  public genarts: Genart[] = [];
  public genartsSubject: BehaviorSubject<Genart[]> = new BehaviorSubject<Genart[]>([]);

  constructor(private http: HttpClient) {}

  getGenarts() {
    this.http.get(`${environment.baseUrl}${GENART_PATH}`).subscribe((data: any) => {
      this.genarts = [];
      if (data && data.length > 0) {
        data.forEach((genartData) => {
          this.genarts.push(Genart.mapToGenart(genartData));
        });
      }
      this.genartsSubject.next(this.genarts);
    });
  }

  getGenartsSubject() {
    this.getGenarts();
    return this.genartsSubject.asObservable();
  }
}
