import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Country } from '../../core/models/country';
import { Promotion } from '../../core/models/promotion';
import { map } from 'rxjs/operators';
import { UtilsService } from '../../core/services/utils.service';
import { environment } from '../../../environments/environment';
import { Recommendation } from '../../core/models/recommendation';
import { Price } from '../../core/models/price';

const PROMOTIONS_PATH = '/promotions';

@Injectable({
  providedIn: 'root'
})
export class PromotionService {

  constructor(private http: HttpClient, private utilsService: UtilsService) {}

  getPromotions(country: Country, nationalPlan?: string, page?: number) {
    const queryParams = this.utilsService.buildQueryParams({nationalPlan, page});
    return this.http.get(`${environment.baseUrl}${PROMOTIONS_PATH}/country/${country.id}${queryParams}`).pipe(
      map((data: any) => {
        const promotions: Array<Promotion> = [];
	        data.objects.forEach((item) => {
		      	promotions.push(Promotion.mapToPromotion(item));
	        });
        return {total: data.totalCount, promotions};
      })
    );
  }

  addLine(newLine: {id: number, recommendation: Recommendation, prices: {[key: number]: number}, startDate: Date, endDate: Date}, country: Country) {
    const body = {
      groupmentId:newLine.id,
      ref: newLine.recommendation.reference,
      // label: newLine.recommendation.label,
      country,
      pricePromoNatPlanList: []
    };

    Object.keys(newLine.prices).forEach((key) => {

    	if(newLine.prices[key] !== undefined && newLine.prices[key] !== null){
	      body.pricePromoNatPlanList.push({
	        price: newLine.prices[key],
	        startDate: newLine.startDate,
	        endDate: newLine.endDate,
	        area: {id: key},
	        nationalPlan: {ref: newLine.recommendation.reference}
	      });
    	}
    });

    return this.http.post(`${environment.baseUrl}${PROMOTIONS_PATH}`, body).pipe(
      map((data) => {
        return Promotion.mapToPromotion(data);
      })
    );
  }

  setLine(newLine: {id: number, recommendation: Recommendation,
          prices: {[key: number]: number}, startDate: Date, endDate: Date},
          country: Country,
          originalPromotion) {
    const body = {
      groupmentId:newLine.id,
      ref: newLine.recommendation.reference,
      // label: newLine.recommendation.label,
      country,
      pricePromoNatPlanList: []
    };


    Object.keys(newLine.prices).forEach((key) => {
          let oldPrice: Price = {};
      if (originalPromotion) {
        oldPrice = originalPromotion.prices.find((price: Price) => +price.areaFull.id === +key);
      }

      if(newLine.prices[key] !== undefined && newLine.prices[key] !== null && newLine.prices[key] !== ''){
	  	if(oldPrice !== undefined && oldPrice !== null){
	      body.pricePromoNatPlanList.push({
	        id: oldPrice.id,
	        price: newLine.prices[key],
	        startDate: newLine.startDate,
	        endDate: newLine.endDate,
	        area: {id: key},
	        nationalPlan: {ref: newLine.recommendation.reference}
	      });
	  	}else{
			body.pricePromoNatPlanList.push({
			    price: newLine.prices[key],
			    startDate: newLine.startDate,
			    endDate: newLine.endDate,
			    area: {id: key},
			    nationalPlan: {ref: newLine.recommendation.reference}
		  });
	  	}
      }
    });
    return this.http.put(`${environment.baseUrl}${PROMOTIONS_PATH}`, body).pipe(
      map((data) => {
        return Promotion.mapToPromotion(data);
      })
    );
  }

  removePromotion(id: number) {
    return this.http.delete(`${environment.baseUrl}${PROMOTIONS_PATH}/groupment/${id}`);
  }
}
