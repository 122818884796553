import {Injectable} from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import {LoginService} from './login.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private loginService: LoginService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url = state.url.substr(1);
    return this.loginService.init(url);
  }
}
