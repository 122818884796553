/**
 * Define the payment state types.
 * @enum {string}
 */
export enum OverhaulStates {
  QUESTIONS = 'QUESTIONS',
  FILTERS = 'FILTERS',
  DIAGNOSTIC = 'DIAGNOSTIC',
  KILOMETERS = 'KILOMETERS',
  MAINTENANCE_PLAN = 'MAINTENANCE_PLAN',
  QUOTATION = 'QUOTATION'
}
