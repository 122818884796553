import { NgModule } from '@angular/core';

import { CoreModule } from '../core/core.module';
import { StandardGridComponent } from './standard-grid.component';
import { RecommendationService } from './services/recommendation.service';

@NgModule({
  declarations: [
    StandardGridComponent
  ],
  imports: [
    CoreModule,
  ],
  providers: [
    RecommendationService
  ]
})
export class StandardGridModule { }
