import { NgModule } from '@angular/core';

import { CoreModule } from '../core/core.module';
import { ConfigurationComponent } from './configuration.component';
import { CountriesGridComponent } from './countries-grid/countries-grid.component';
import { PrestationsGridComponent } from './prestations-grid/prestations-grid.component';
import { OperationsGridComponent } from './operations-grid/operations-grid.component';
import { OperationsService } from './operations-grid/services/operations.service';
import { MappingComponent } from './mapping/mapping.component';
import { RulesService } from './rules/services/rules.service';
import { RulesComponent } from './rules/rules.component';
import { OilsComponent } from './oils/oils.component';
import { OilsContainerComponent } from './oils-container/oils-container.component';
import { MatCheckboxModule, MatInputModule, MatPaginatorModule, MatSortModule, MatTableModule } from '@angular/material';
import { MatIconModule } from '@angular/material/icon';
@NgModule({
  declarations: [
    ConfigurationComponent,
    CountriesGridComponent,
    PrestationsGridComponent,
    OperationsGridComponent,
    RulesComponent,
    MappingComponent,
    OilsComponent,
    OilsContainerComponent
  ],
  imports: [
    CoreModule,
    MatTableModule,
    MatInputModule,
    MatCheckboxModule,
    MatIconModule
  ],
  providers: [
    OperationsService,
    RulesService
  ]
})
export class ConfigurationModule { }
