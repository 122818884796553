import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Country } from '../../core/models/country';
import { Discount } from '../../core/models/discount';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Prestation } from '../../core/models/prestation';
import { UtilsService } from '../../core/services/utils.service';

const NATIONAL_DISCOUNT_PATH = '/nationaldiscountrates';
const BY_COUNTRY_PATH = '/country';

@Injectable({
  providedIn: 'root'
})
export class DiscountService {

  constructor(private http: HttpClient, private utilsService: UtilsService) { }

  getDiscounts(country: Country, search?: string, page?: number) {
    const url = `${environment.baseUrl}${NATIONAL_DISCOUNT_PATH}${BY_COUNTRY_PATH}/${country.id}` +
      this.utilsService.buildQueryParams({ search, page });
    return this.http.get(url).pipe(
      map((data: any) => {
        const discounts: Array<Discount> = [];
        data.objects.forEach((item) => {
          discounts.push(Discount.mapToDiscount(item));
        });
        return { total: data.totalCount, discounts };
      })
    );
  }

  addLine(newLine: Discount, country: Country) {
    const body = {
      rate: newLine.rate,
      startDate: newLine.startDate,
      endDate: newLine.endDate,
      product: Prestation.mapFromPrestation(newLine.prestation),
      country
    };

    return this.http.post(`${environment.baseUrl}${NATIONAL_DISCOUNT_PATH}`, body).pipe(
      map((data: any) => {
        return Discount.mapToDiscount(data);
      })
    );
  }

  setLine(newLine: Discount, country: Country) {
    const body = {
      id: newLine.id,
      rate: newLine.rate,
      startDate: newLine.startDate,
      endDate: newLine.endDate,
      product: Prestation.mapFromPrestation(newLine.prestation),
      country
    };

    return this.http.put(`${environment.baseUrl}${NATIONAL_DISCOUNT_PATH}`, body).pipe(
      map((data: any) => {
        return Discount.mapToDiscount(data);
      })
    );
  }

  removeDiscount(discountId: number) {
    return this.http.delete(`${environment.baseUrl}${NATIONAL_DISCOUNT_PATH}/${discountId}`);
  }

  doesDiscountAlreadyExistsForThePeriod(discounts: Discount[], newDiscount: Discount): boolean {
    let discountAlreadyExistsForThePeriod = false;
    discountAlreadyExistsForThePeriod = discounts.some(discount => {
        return (newDiscount.id !== discount.id && newDiscount.prestation.id === discount.prestation.id && newDiscount.startDate <= discount.endDate && newDiscount.endDate >= discount.startDate);
    });
    return discountAlreadyExistsForThePeriod;
  }
}
