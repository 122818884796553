import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import * as moment from 'moment';
import { Moment } from 'moment';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

  // --------------------------------------------
  //           PROPERTIES
  // --------------------------------------------

  /**
   * First (or only) day of the selection.
   */
  @Input()
  public selectedDay: Moment;

  @Output()
  public selectedDayChange = new EventEmitter<Moment>();

  /**
   * Last day of the selection.
   */
  @Input()
  public lastSelectedDay: Moment;

  @Output()
  public lastSelectedDayChange = new EventEmitter<Moment>();

  /**
   * Define if we want to display a whole year.
   * Set to 'true' to display all the month of each year.
   * Set to 'false' to display one month at a time.
   */
  @Input()
  public displayFullYear = false;

  /**
   * Define if we want to allow the user to define a selection.
   * If set to 'true', the user can define a selection.
   * If set to 'false' the user can define a single day.
   */
  @Input()
  public allowSelection = false;

  /**
   * Define the size of the calendar.
   */
  @Input()
  public small = false;

  /**
   * Define if the calendar grid has flat style.
   * If set to 'true', the calendar borders are disabled.
   */
  @Input()
  public flatStyle = false;

  public monthNames = [
      this.capitalize(moment().month(0).format('MMMM')),
      this.capitalize(moment().month(1).format('MMMM')),
      this.capitalize(moment().month(2).format('MMMM')),
      this.capitalize(moment().month(3).format('MMMM')),
      this.capitalize(moment().month(4).format('MMMM')),
      this.capitalize(moment().month(5).format('MMMM')),
      this.capitalize(moment().month(6).format('MMMM')),
      this.capitalize(moment().month(7).format('MMMM')),
      this.capitalize(moment().month(8).format('MMMM')),
      this.capitalize(moment().month(9).format('MMMM')),
      this.capitalize(moment().month(10).format('MMMM')),
      this.capitalize(moment().month(11).format('MMMM')),
  ];

  public dayNames = [
      this.capitalize(moment().day(1).format('dd')),
      this.capitalize(moment().day(2).format('dd')),
      this.capitalize(moment().day(3).format('dd')),
      this.capitalize(moment().day(4).format('dd')),
      this.capitalize(moment().day(5).format('dd')),
      this.capitalize(moment().day(6).format('dd')),
      this.capitalize(moment().day(7).format('dd')),
  ];

  public monthsList: any[] = [];
  public currentMonth: any = [];

  public year: number;
  public currentSelection: boolean;

  private date: Moment;


  // --------------------------------------------
  //           CONSTRUCTOR
  // --------------------------------------------

  constructor() {}


  // --------------------------------------------
  //           METHODS
  // --------------------------------------------

  /**
   * Set the current month.
   */
  ngOnInit(): void {
      this.setMonthYear(this.selectedDay || moment());
  }

  /**
   * Capitalize the given string (i.e. convert the string to upper lower case).
   */
  capitalize(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  /**
   * Set the month.
   */
  setMonthYear(date: Moment): void {
      this.year = date.year();
      this.date = moment(date);
      const cursor = moment(date).startOf('year');
      const currentMonth = moment(date).month();
      let monthId = 0;

      while (monthId < 12) {
          const month = cursor.month();
          this.monthsList[month] = {
              name: this.monthNames[month],
              weeks: this.getMonthWeeks(cursor),
              days: this.getMonthDays(cursor)
          };

          if (month === currentMonth) {
              this.currentMonth = this.monthsList[month];
          }

          cursor.add(1, 'month');
          monthId = monthId + 1;
      }
  }

  /**
   * Set Date, Year, currentMonth of calendar.
   */
  setDateByPicker(date: Moment): void {
      this.year = date.year();
      this.date = moment(date);
      this.currentMonth = {
          name: this.monthNames[date.month()],
          weeks: this.getMonthWeeks(date),
          days: this.getMonthDays(date)
      };
  }

  /**
   * Change the current date.
   */
  changeDate(increment: number, dateType: moment.unitOfTime.DurationConstructor): void {
      if (increment === 0) {
          this.setMonthYear(moment());

      } else {
          this.setMonthYear(this.date.add(increment, dateType));
      }
  }

  /**
   * Change the current year.
   */
  changeYear(increment: number): void {
      this.changeDate(increment, 'year');
  }

  /**
   * Change the current month.
   */
  changeMonth(increment: number): void {
      this.changeDate(increment, 'month');
  }

  /**
   * Select the period
   */
  selectPeriod(day: any): void {
      if (!day.outScope) {
          if (!this.currentSelection || !this.allowSelection) {
              this.selectedDay = moment(day.date);
              this.lastSelectedDay = undefined;
              this.currentSelection = true;

          } else if (this.allowSelection) {
              if (day.date.diff(this.selectedDay) < 0) {
                  this.lastSelectedDay = this.selectedDay;
                  this.selectedDay = moment(day.date);

              } else {
                  this.lastSelectedDay = moment(day.date);
              }

              this.currentSelection = false;
          }
      }
      this.selectionChanged();
  }

  /**
   * Trigger event of component parent on selection changes.
   */
  selectionChanged(): void {
      this.selectedDayChange.emit(this.selectedDay);

      if (this.allowSelection) {
          this.lastSelectedDayChange.emit(this.lastSelectedDay);
      }
  }

  /**
   * Cancel the current selection.
   */
  cancelSelection(): void {
      this.selectedDay = undefined;
      this.lastSelectedDay = undefined;
      this.currentSelection = false;
  }

  /**
   * Check if the given date is today.
   */
  isToday(date: Moment): boolean {
      const today = moment();
      return today.date() === date.date() && today.month() === date.month() && today.year() === date.year();
  }

  /**
   * Check if the given date is start or end of the selection.
   */
  isStartOrEnd(date: Moment): boolean {
      return (this.selectedDay && date.diff(this.selectedDay) === 0)
          || (this.lastSelectedDay && date.diff(this.lastSelectedDay) === 0);
  }

  /**
   * Check if the given date is selected.
   */
  isSelected(date: Moment): boolean {
      return this.selectedDay
          && date.diff(this.selectedDay) > 0
          && this.lastSelectedDay
          && date.diff(this.lastSelectedDay) < 0;
  }

  /**
   * Get the weeks of the month.
   */
  private getMonthWeeks(date: Moment): number[] {
      const cursor = moment(date).startOf('month');
      const weeks: number[] = [];
      weeks.push(cursor.week());
      cursor.weekday(0);
      cursor.add(1, 'week');
      const month = cursor.month();

      while (cursor.month() === month) {
          weeks.push(cursor.week());
          cursor.add(1, 'week');
      }

      return weeks;
  }

  /**
   * Get the days of the month.
   */
  private getMonthDays(date: Moment) {
      const days: any[] = [];
      let cursor = moment(date).startOf('month');
      const month = cursor.month();

      while (cursor.weekday() !== 0) {
          cursor.subtract(1, 'd');
          days.unshift({ outScope: true, number: cursor.date(), date: moment(cursor), events: [] });
      }

      cursor = moment(date).startOf('month');
      while (cursor.month() === month) {
          days.push({ outScope: false, number: cursor.date(), date: moment(cursor), events: [] });
          cursor.add(1, 'd');
      }

      while (cursor.weekday() > 0) {
          days.push({ outScope: true, number: cursor.date(), date: moment(cursor), events: [] });
          cursor.add(1, 'd');
      }

      return days;
  }
}
