import { Component, OnInit } from '@angular/core';
import { PrestationsService } from '../../core/services/prestations.service';
import { OperationsService } from '../operations-grid/services/operations.service';
import { Prestation } from '../../core/models/prestation';
import { Operation } from '../../core/models/operation';
import { I18nService } from '../../core/services/i18n.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { CountriesService } from "src/app/core/services/countries.service";
import { Country } from "src/app/core/models/country";

@Component({
  selector: 'app-mapping',
  templateUrl: './mapping.component.html',
  styleUrls: ['./mapping.component.scss']
})
export class MappingComponent implements OnInit {

  public searchText: string;
  public operations: Array<Operation> = [];
  public prestations: Array<Prestation> = [];
  public loadingPage = false;

  private lastPage = 0;
  private totalItem = 0;
  private searchTimeout: any;

  public lang = 'En';

  public selectedOperations: { [prestationId: string]: Operation } = {};
  public openedPrestation: { [prestationId: string]: boolean } = {};

  public countries: Array<Country> = [];
  public selectedCountry: Country;

  constructor(private prestationsService: PrestationsService,
    private operationsService: OperationsService,
    private toastService: ToastService,
    private countriesService: CountriesService,
    private i18nService: I18nService) { }

  ngOnInit() {
    this.getCountries();
    this.getPrestations(0);
    this.getOperations();
    this.i18nService.getLang().subscribe(
      (lang: string) => {
        this.lang = lang.charAt(0).toUpperCase() + lang.substr(1).toLowerCase();
      }
    );
  }

  searchChanged() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.getPrestations(0);
      this.searchTimeout = undefined;
    }, 300);
  }

  getNextPage() {
    if (!this.loadingPage && this.totalItem && this.prestations.length < this.totalItem) {
      this.getPrestations(this.lastPage + 1);
    }
  }

  getPrestations(page?: number) {
    this.loadingPage = true;
    this.prestationsService.getPrestations(this.searchText, page, false, (this.selectedCountry ? this.selectedCountry.code : 'FR')).subscribe(
      (result: { total: number, prestations: Array<Prestation> }) => {
        this.totalItem = result.total;
        this.lastPage = page;
        if (result.prestations && result.prestations.length > 0) {
          if (page === 0) {
            this.setPrestations(result.prestations);
          } else {
            this.setPrestations([...this.prestations, ...result.prestations]);
          }
        }
        this.loadingPage = false;
      }
    );
  }

  setPrestations(prestations: Array<Prestation>) {
    this.prestations = prestations;
    this.setOpenedPrestations();
  }

  setOpenedPrestations() {
    this.prestations.forEach((prestation) => {
      if (!this.openedPrestation[prestation.id]) {
        this.openedPrestation[prestation.id] = false;
      }
    });
  }

  getOperations(searchText?: string) {
    this.operationsService.getOperations(searchText, 0, true, (this.selectedCountry ? this.selectedCountry.code : 'FR')).subscribe(
      (results: { total: number, operations: Array<Operation> }) => {
        this.setOperations(results.operations);
      }
    );
  }

  setOperations(operations: Array<Operation>) {
    this.operations = [];
    operations.forEach((operation) => {
      if (!operation.prestationId) {
        this.operations.push(operation);
      }
    });
  }

  addOperation(prestation: Prestation) {
    if (this.selectedOperations[prestation.id]) {
      this.prestationsService.addOperation(prestation, this.selectedOperations[prestation.id]
        , (this.selectedCountry ? this.selectedCountry.code : 'FR')).subscribe(
        () => {
          const operationCopy = JSON.parse(JSON.stringify(this.selectedOperations[prestation.id]));
          prestation.operations.push(operationCopy);
          this.getOperations();
          this.selectedOperations[prestation.id] = undefined;
        },
        error => {
          this.toastService.setToast('APP.MAPPING.ERRORS.ALREADY-ASSIGNED', 'error');
          console.error(error);
        }
      );

    }
  }

  removeOperation(prestation: Prestation, operation: Operation) {
    this.prestationsService.removeOperation(prestation, operation, (this.selectedCountry ? this.selectedCountry.code : 'FR')).subscribe(
      () => {
        const index = prestation.operations.findIndex((o) => operation.id === o.id);
        prestation.operations.splice(index, 1);
        this.getOperations();
      }
    );
  }

  getCountries(searchText?: string) {
    this.countriesService
      .getCountries(searchText)
      .subscribe((result: { total: number; countries:  Array<Country >}) => {
        if (result.countries && result.countries.length > 0) {
          this.setCountries(result.countries);
          this.setDefaultCountry();
        }
      });
  }

  setCountries(countries: Array<Country>) {
    this.countries = countries;
    if (this.countriesService.countryId) {
      this.selectedCountry = this.countries.find(
        (country: Country) => country.id === this.countriesService.countryId
      );
    //  this.loadOperationAndProductConfigurationForSelectedCountry();
    }
  }

  setDefaultCountry() {
    if (this.countries) {
      this.selectedCountry = this.countries.find(
        (country: Country) => country.code == 'FR'
      );
    }
  }

  onCountryChange(country: Country) {
    this.selectedCountry = country;
    this.countriesService.setCountry(country.id);
   this.getPrestations(0)
    this.getOperations();

  }



}
