import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-radio-button',
    templateUrl: './radio-button.component.html',
    styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent implements OnInit {

    // --------------------------------------------------
    //                     PROPERTIES
    // --------------------------------------------------

    @Input() options: any[];
    @Input() multiple = false;
    @Input() optionSelected: any;
    @Input() radioButtonDescriptor: {
        name: string,
        value: string
    };

    @Output() optionSelectedChange = new EventEmitter<any>();


    // --------------------------------------------------
    //                     CONSTRUCTOR
    // --------------------------------------------------

    constructor() {}


    // --------------------------------------------------
    //                     METHODS
    // --------------------------------------------------

    /**
     * Component initialization
     */
    ngOnInit() {}

    selectOption(option: any) {
        if (this.radioButtonDescriptor && this.radioButtonDescriptor.value) {
            this.selectValue(option[this.radioButtonDescriptor.value]);
        } else {
            this.selectValue(option);
        }

        this.optionSelectedChange.emit(this.optionSelected);
    }

    /**
     * Add value to selected option
     * @param option
     */
    selectValue(option) {
        if (this.multiple && Array.isArray(this.optionSelected)) {
            const index = this.optionSelected.findIndex((o: number ) => o === option);
            if (index > -1) {
                this.optionSelected.splice(index, 1);
            } else {
                this.optionSelected.push(option);
            }
        } else {
            this.optionSelected = option;
        }
    }

    /**
     * Return if the given option is selected
     * @param option
     */
    isSelected(option: any) {
        let optionValue = option;
        if (this.radioButtonDescriptor && this.radioButtonDescriptor.value) {
            optionValue = option[this.radioButtonDescriptor.value];
        }
        if (this.multiple && Array.isArray(this.optionSelected)) {
            const index = this.optionSelected.findIndex((o: number ) => o === optionValue);
            return index > -1;
        } else {
          return optionValue === this.optionSelected;
        }
    }
}
