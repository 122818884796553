export class Price {

  constructor( public id?: number,
               public zone?: string,
               public price?: number,
               public currency?: string,
               public areaFull?: {id: number, label: string}
  ) {}

  static mapToPrice(data: any) {
    let zone: string;

    if (data.area) {
      zone = data.area.label;
    }

    return new Price(
      data.id,
      zone,
      data.price,
      data.currency,
      data.area
    );
  }
}
