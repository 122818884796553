import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss']
})
export class SelectComponent {


    // --------------------------------------------------
    //                     PROPERTIES
    // --------------------------------------------------


    @Output() searchChanged = new EventEmitter<string>();
    @Output() selectionChanged = new EventEmitter<any>();
    @Output() pageChangeBehavior = new EventEmitter<number>();
    @Output() selectClosed = new EventEmitter<void>();

    @Input() selectOptions: any[] = [];
    @Input() selectedOption: any;
    @Input() descriptor: { name: string, value: string };
    @Input() placeholder: string;
    @Input() showedSearchBar: boolean;
    @Input() name: string;
    @Input() hasPagination: boolean;

    @ViewChild('inputSearchSelect') inputEl: ElementRef;
    @ViewChild('inputSearchSelectOptions') optionsEl: ElementRef;

    public showedSelect = false;
    public searchText = '';
    public selectOpened = false;



    // --------------------------------------------------
    //                     CONSTRUCTOR
    // --------------------------------------------------


    constructor() { }



    // --------------------------------------------------
    //                       METHODS
    // --------------------------------------------------

    /**
     * Returns selected options or placeholder value if nothing is selected.
     */
    getSelectedOption(): string {
        if (this.selectedOption && this.descriptor && this.descriptor.name) {
            return this.selectedOption[this.descriptor.name];
        } else {
            return this.placeholder;
        }
    }

    onScrollThreshold() {
      if (this.hasPagination) {
        this.pageChangeBehavior.emit();
      }
    }

    /**
     * Open popup of options.
     */
    openSelect() {
        if (!this.showedSelect) {
            this.selectOpened = true;
            this.showedSelect = true;

            // Set focus on input search bar when opened
            if (this.showedSearchBar) {
                setTimeout(() => this.inputEl.nativeElement.focus(), 0);
            }
            setTimeout(() => {
                this.selectOpened = false;
            }, 500);
        }
    }


    /**
     * Open Select search bar when focused
     */
    onKeyPlaceholder(event: any) {
        if (event.code === 'Enter' || event.code === 'ArrowDown') {
            this.openSelect();
        } else if (event.code === 'Escape') {
            this.closePopup();
        }
    }


    /**
     * Close Select search bar when focused and escape key pressed
     */
    onKeySelect(event: any) {
        if (event.code === 'Escape') {
            this.closePopup();
        } else if (event.code === 'ArrowDown') {
            // Focus options when arrow down key pressed.
            setTimeout(() => this.optionsEl.nativeElement.focus(), 0);
        }
    }


    /**
     * Close Select search bar when focused and escape key pressed
     */
    onKeyOptions(event: any) {
        if (event.code === 'Escape') {
            this.closePopup();
        }
    }


    /**
     * Trigger event of component on Select search bar.
     */
    searchChange() {
        this.searchChanged.emit(this.searchText);
    }

    /**
     * Trigger event of component parent on Select changes.
     */
    selectChanged(item: any) {
        this.selectionChanged.emit(item);
        this.closePopup();
    }



    /**
     * Close the Select popup if it's opened.
     */
    closePopup() {
        if (!this.selectOpened) {
            this.showedSelect = false;
            this.selectClosed.emit();
        }
    }


    /**
     * Remove search bar value and display options.
     */
    clearSearchBar() {
        this.searchText = '';
        this.searchChange();
    }
}
