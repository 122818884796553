import { Component, Input } from '@angular/core';
import { ToastDetailledErrorService } from '../services/toast-detailled-error.service';

@Component({
  selector: 'app-toast-detailled-error',
  templateUrl: './toast-detailled-error.component.html',
  styleUrls: ['./toast-detailled-error.component.scss']
})
export class ToastDetailledErrorComponent {

  @Input() errorMessage: string;
  @Input() errorStatus: string;
  @Input() messageDetail : string[];

  isShown: boolean = false ;

  constructor(private toastDetailledErrorService: ToastDetailledErrorService) {}

  closeToastDetailledError() {
	this.closeDetailledMessage();
    this.toastDetailledErrorService.resetToastDetailledError();
  }
  
  toggleShow() {
	  this.isShown = ! this.isShown;
  }
  
  closeDetailledMessage() {
	  this.isShown = false;
  }

}
