import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  buildQueryParams(params: any) {
    let queryParams = '';
    const keys = Object.keys(params);
    if (keys && keys.length > 0) {
      keys.forEach((key) => {
        if (params[key] || params[key] === 0) {
          queryParams = `${queryParams}&${key}=${params[key]}`;
        }
      });
      queryParams = queryParams.replace('&', '?');
    }
    return queryParams;
  }
}
