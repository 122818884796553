import { GridAction } from './grid-action';
import { GridColumnDescriptor } from './grid-column-descriptor';

export class GridDescriptor {

  constructor( public columns: Array<GridColumnDescriptor>,
               public actions?: Array<GridAction>,
               public actionWeight?: string,
               public totalWeight?: number,
               public newLine?: boolean
  ) {}
}
