export class Genart {
  constructor( public code?: number,
               public codeTecDoc?: number,
               public labelFr?: string,
               public labelEn?: string,
               public labelEs?: string,
               public concatenationFr?: string,
               public concatenationEn?: string,
               public concatenationEs?: string) {}

  static mapToGenart(data) {
    const code = data.codeTecDoc == null ? data.code : data.codeTecDoc;

    const concatenationFr = `${code} ${data.labelFr}`;
    const concatenationEn = `${code} ${data.labelEn}`;
    const concatenationEs = `${code} ${data.labelEs}`;
    
    return new Genart(
      data.code,
      code,
      data.labelFr,
      data.labelEn,
      data.labelEs,
      concatenationFr,
      concatenationEn,
      concatenationEs
    );
  }
}
