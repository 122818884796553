import {
  Directive,
  ElementRef,
  Output,
  EventEmitter,
  HostListener,
  DoCheck
} from '@angular/core';

@Directive({
  selector: '[appInfiniteScroll]'
})
export class InfiniteScrollDirective implements DoCheck {
  constructor(private elementRef: ElementRef) { }

  @Output() public appInfiniteScroll = new EventEmitter<void>();

  @HostListener('scroll')
  public onScroll() {
    if ((this.elementRef.nativeElement.scrollTop + this.elementRef.nativeElement.offsetHeight) >=
      (0.6 * this.elementRef.nativeElement.scrollHeight)) {
        this.appInfiniteScroll.emit();
    }
  }

  ngDoCheck(): void {
    if (this.elementRef.nativeElement.offsetHeight > this.elementRef.nativeElement.scrollHeight) {
      this.appInfiniteScroll.emit();
    }
  }
}
