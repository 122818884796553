import { NgModule } from '@angular/core';

import { CoreModule } from '../core/core.module';
import { DiscountGridComponent } from './discount-grid.component';
import { DiscountService } from './services/discount.service';

@NgModule({
  declarations: [
    DiscountGridComponent
  ],
  imports: [
    CoreModule,
  ],
  providers: [
    DiscountService
  ]
})
export class DiscountGridModule { }
