import { NgModule } from '@angular/core';

import { CoreModule } from '../core/core.module';
import { DetailComponent } from './detail.component';
import { DetailService } from './detail.service';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { DetailrequestService } from './detailrequest.service';
import { OverhaulComponent } from './overhaul/overhaul.component';
import { OverhaulService } from './overhaul/overhaul.service';
import { RadioButtonModule } from '../core/radio-button/radio-button.module';

@NgModule({
  declarations: [
    DetailComponent,
    OverhaulComponent
  ],
  imports: [
    CoreModule,
    RadioButtonModule,
    NgxJsonViewerModule
  ],
  providers: [
    DetailService,
    DetailrequestService,
    OverhaulService
  ]
})
export class DetailModule { }
