import {Area} from './area';

export class Country {

  constructor(public id?: number,
              public label?: string,
              public code?: string,
              public currency?: string,
              public vatRate?: number,
              public listArea?: Array<Area>
  ) {
  }

  static mapToCountry(data: any) {
    const listArea: Array<Area> = [];
    if (data.listArea && data.listArea.length > 0) {
      data.listArea.forEach((area) => {
        listArea.push(area);
      });
      listArea.sort((a: Area, b: Area) => {
        return a.id - b.id;
      });
    }

    return new Country(
      data.id,
      data.label,
      data.code,
      data.currency,
      data.vatRate,
      listArea
    );
  }
}
