import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '../../../core/services/utils.service';
import { Oils } from '../../../core/models/oils';
import { map } from 'rxjs/operators';
import { Operation } from '../../../core/models/operation';
import { Genart } from '../../../core/models/genart';
import { Country } from 'src/app/core/models/country';

const OIL_PATH = '/oilviscocity';
// const OIL_PATH = '/oil';

@Injectable({
  providedIn: 'root'
})
export class OilsService {

  constructor(private http: HttpClient, private utilsService: UtilsService) { }

  // getOilByCountry(country: Country, oils: Oils) {

  getOilByCountry(country: Country, oils: Oils) {
    return this.http.get(`${environment.baseUrl}${OIL_PATH}/country/${country.id}`);
  }

  updateOilList(body: any) {

    return this.http.put(`${environment.baseUrl}${OIL_PATH}`, body);
  }
 setLine(country: Country, newLine: Oils) {
        console.log('NEWLINE(setLine)----- ' + JSON.stringify(newLine));
    const body = {
      id: newLine.id,
      oilName: newLine.oilName,
      priorityOrder: newLine.priorityOrder,
      id_country: country.id
    };
    return this.http.put(`${environment.baseUrl}${OIL_PATH}/modify`, body);
  }
    addLine(country: Country, newLine: Oils) {
        console.log('NEWLINE----- ' + JSON.stringify(newLine));
    const body = {
      id: newLine.id,
      oilName: newLine.oilName,
      priorityOrder: newLine.priorityOrder,
      id_country: country.id
    };
    console.log('ADD LINE BODY--- ' + JSON.stringify(body));
        console.log('ID--' + newLine.id);
    return this.http.post(`${environment.baseUrl}${OIL_PATH}`, body);
  }
  removeOils(id: number) {
    // console.log('DELETE ID==     ' + id);
    return this.http.delete(`${environment.baseUrl}${OIL_PATH}/${id}`);
  }
}
