import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {UtilsService} from '../../../core/services/utils.service';
import {map} from 'rxjs/operators';
import {Operation} from '../../../core/models/operation';
import {Genart} from '../../../core/models/genart';

const OPERATIONS_PATH = '/operations';

@Injectable({
  providedIn: 'root'
})
export class OperationsService {

  constructor(private http: HttpClient, private utilsService: UtilsService) {
  }

  getOperations(search?: string, page?: number, onlyNotAssigned?: boolean, country?: string) {
    const url = `${environment.baseUrl}${OPERATIONS_PATH}${this.utilsService.buildQueryParams({search, page, onlyNotAssigned})}`;
    const headers = new HttpHeaders({
      'X-Country': country ? country : ''
    });
    return this.http.get(url, {headers: headers}).pipe(
      map((data: any) => {
        const operations: Array<Operation> = [];
        data.objects.forEach((item) => {
          operations.push(Operation.mapToOperation(item));
        });
        return {total: data.totalCount, operations: operations};
      })
    );
  }


  addLine(newLine: {
    code: string,
    labelFr: string,
    labelEn: string,
    labelEs: string,
    type: { value: string, label: string },
    brrNumber: number,
    genarts: Genart[],
    id?: number
  }) {
    const body = {
      ref: newLine.code,
      labelFr: newLine.labelFr,
      labelEn: newLine.labelEn,
      labelEs: newLine.labelEs,
      operationEnum: newLine.type ? newLine.type.value : undefined,
      brrColumn: newLine.brrNumber,
      genarts: newLine.genarts.map((genart) => {
        return {labelFr: genart.labelFr, labelEn: genart.labelEn, labelEs: genart.labelEs, code: genart.code};
      })
    };
    return this.http.post(`${environment.baseUrl}${OPERATIONS_PATH}`, body).pipe(
      map((operationDate: any) => {
        return Operation.mapToOperation(operationDate);
      })
    );
  }

  setLine(newLine: {
    code: string,
    labelFr: string,
    labelEn: string,
    labelEs: string,
    type: { value: string, label: string },
    brrNumber: number,
    genarts: Genart[],
    id?: number
  }) {
    const body = {
      id: newLine.id,
      ref: newLine.code,
      labelFr: newLine.labelFr,
      labelEn: newLine.labelEn,
      labelEs: newLine.labelEs,
      operationEnum: newLine.type ? newLine.type.value : undefined,
      brrColumn: newLine.brrNumber,
      genarts: newLine.genarts.map((genart) => {
        return {labelFr: genart.labelFr, labelEn: genart.labelEn, labelEs: genart.labelEs, code: genart.code};
      })
    };
    return this.http.put(`${environment.baseUrl}${OPERATIONS_PATH}`, body).pipe(
      map((operationDate: any) => {
        return Operation.mapToOperation(operationDate);
      })
    );
  }

  removeOperation(operationId: number) {
    return this.http.delete(`${environment.baseUrl}${OPERATIONS_PATH}/${operationId}`);
  }
}
