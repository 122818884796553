import { Component, OnInit } from '@angular/core';
import { GridDescriptor } from '../../core/models/grid/grid-descriptor';
import { Prestation } from '../../core/models/prestation';
import { PrestationsService } from '../../core/services/prestations.service';
import { Observable } from 'rxjs';
import { ModalDescriptor } from '../../core/models/modal/modal-descriptor';

@Component({
  selector: 'app-prestations-grid',
  templateUrl: './prestations-grid.component.html',
  styleUrls: ['./prestations-grid.component.scss']
})
export class PrestationsGridComponent implements OnInit {
  public descriptor: GridDescriptor;
  public isModifOK:boolean = false;

  public newLineStructure: {
    labelFr: string,
    labelEn: string,
    labelEs: string,
    reference: string,
    brrNumber: number,
    brrBillingType: number
  } = {
    labelFr: '',
    labelEn: '',
    labelEs: '',
    reference: '',
    brrNumber: undefined,
    brrBillingType: undefined
  };

  public prestations: Array<Prestation> = [];

  private modalDescriptorModel: ModalDescriptor = {
    width: 450,
    height: 200,
    title: '',
    titleParameters: {},
    message: '',
    messageParameters: {},
    showModal: false,
    actions: [],
    clickOutsideBehavior: undefined,
    clickOutsideBehaviorParameters: []
  };

  public modalDescriptor: ModalDescriptor = JSON.parse(JSON.stringify(this.modalDescriptorModel));

  public searchText = '';
  private searchTimeout: any;

  public totalItem = 0;
  public loadingPage = false;
  private lastPage = 0;

  constructor(private prestationsService: PrestationsService) {}

  ngOnInit() {
    this.getPrestations(0);
    this.setDescriptor();
  }

  searchChanged() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.getPrestations(0);
      this.searchTimeout = undefined;
    }, 300);
  }

  getNextPage() {
    if (!this.loadingPage && this.totalItem && this.prestations.length < this.totalItem) {
      this.getPrestations(this.lastPage + 1);
    }
  }

  getPrestations(page?: number) {
    this.loadingPage = true;
    this.prestationsService.getPrestations(this.searchText, page).subscribe(
      (result: {total: number, prestations: Array<Prestation>}) => {
        this.totalItem = result.total;
        this.lastPage = page;
        if (result.prestations) {
          if (page === 0) {
            this.setPrestations(result.prestations);
          } else {
            this.setPrestations([...this.prestations, ...result.prestations]);
          }
        }
        this.loadingPage = false;
      }
    );
  }

  setPrestations(prestations: Array<Prestation>) {
    this.prestations = prestations;
  }

  setDescriptor() {
    this.descriptor = {
      columns: [
        {
          id: 1,
          label: 'APP.PRESTATIONS_GRID.REFERENCE',
          labelParameters: {},
          value: 'reference',
          type: 'text',
          required: true,
          weight: '3'
        },
        {
          id: 2,
          label: 'APP.PRESTATIONS_GRID.LABEL_FR',
          labelParameters: {},
          value: 'labelFr',
          type: 'text-only',
          required: true,
          weight: '3'
        },
        {
          id: 3,
          label: 'APP.PRESTATIONS_GRID.LABEL_EN',
          labelParameters: {},
          value: 'labelEn',
          type: 'text-only',
          required: true,
          weight: '3'
        },
        {
          id: 4,
          label: 'APP.PRESTATIONS_GRID.LABEL_ES',
          labelParameters: {},
          value: 'labelEs',
          type: 'text-only',
          required: true,
          weight: '3'
        },
        {
          id: 5,
          label: 'APP.PRESTATIONS_GRID.BRR_NUMBER',
          labelParameters: {},
          value: 'brrNumber',
          type: 'number-only',
          required: true,
          weight: '2'
        },
        {
          id: 6,
          label: 'APP.PRESTATIONS_GRID.BRR_BILLING_TYPE',
          labelParameters: {},
          value: 'brrBillingType',
          type: 'number-only',
          required: true,
          weight: '2'
        }
      ],
      actions: [
        {
          label: 'remove',
          tooltip: 'APP.PRESTATIONS_GRID.REMOVE_LINE_TOOLTIP',
          icon: 'fa-trash error-button',
          behavior: this.removePrestation.bind(this),
          confirmation: true,
          parameters: []
        }
      ],
      actionWeight: '1',
      newLine: true
    };
  }

  addLine(
    newLine:
    {labelFr: string, labelEn: string, labelEs: string, reference: string, brrNumber: number, brrBillingType: number, id?: number}
    ) {
    if (newLine.id) {
      this.prestationsService.setLine(newLine).subscribe(
        (prestation: Prestation) => {
          const index = this.prestations.findIndex((d) => d.id === prestation.id);
          if (index > -1) {
            this.prestations[index] = prestation;
          } else {
            this.prestations.push(prestation);
          }
          this.isModifOK = !this.isModifOK;
        }
      );
    } else {
      this.prestationsService.addLine(newLine).subscribe(
        (newPrestation: Prestation) => {
          this.prestations.push(newPrestation);
          this.isModifOK = !this.isModifOK;
        }
      );
    }
  }

  closeModal() {
    this.modalDescriptor = JSON.parse(JSON.stringify(this.modalDescriptorModel));
  }

  confirmModal(observer) {
    observer.next(true);
    observer.complete();
    this.closeModal();
  }

  cancelModal(observer) {
    observer.next(false);
    observer.complete();
    this.closeModal();
  }

  openRemoveModal(prestation: Prestation) {
    return new Observable((observer) => {
      this.modalDescriptor.title = 'APP.PRESTATIONS_GRID.REMOVE_MODAL_TITLE';
      this.modalDescriptor.message = 'APP.PRESTATIONS_GRID.REMOVE_MODAL_MESSAGE';
      this.modalDescriptor.actions.push({
        icon: '',
        label: 'APP.ACTIONS.CANCEL',
        color: 'white',
        behavior: this.cancelModal.bind(this),
        behaviorParameters: [observer]
      });
      this.modalDescriptor.actions.push({
        icon: '',
        label: 'APP.ACTIONS.CONFIRM',
        color: 'red',
        behavior: this.confirmModal.bind(this),
        behaviorParameters: [observer]
      });
      this.modalDescriptor.clickOutsideBehavior = this.cancelModal.bind(this);
      this.modalDescriptor.clickOutsideBehaviorParameters = [observer];
      this.modalDescriptor.showModal = true;
    });
  }

  removePrestation(prestationId: number) {
    const index = this.prestations.findIndex((prestation) => prestation.id === prestationId);
    if (index > -1) {
      this.openRemoveModal(this.prestations[index]).subscribe(
        (success: boolean) => {
          if (success) {
            this.prestationsService.removePrestation(prestationId).subscribe(
              (data) => {
                const newPrestations = this.prestations;
                newPrestations.splice(index, 1);
                this.setPrestations(newPrestations);
              }
            );
          }
        }
      );
    }
  }
}
