import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class I18nService {

  public lang = 'en';
  public langSubject: BehaviorSubject<string> = new BehaviorSubject<string>(this.lang);

  public locale = 'en-US';

  private locales = {
    en: 'en-US',
    fr: 'fr-FR',
    es: 'es-ES'
  };

  constructor(private translate: TranslateService) {}

  getLang() {
    return this.langSubject.asObservable();
  }

  setLocale(lang: string) {
    this.translate.setDefaultLang(lang);
    moment.locale(lang);
    this.lang = lang;
    this.langSubject.next(this.lang);
    this.locale = this.locales[lang];
  }
}
