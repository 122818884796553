import { Component, OnDestroy, OnInit } from '@angular/core';
import { Country } from 'src/app/core/models/country';
import { CountriesService } from 'src/app/core/services/countries.service';
import { GridDescriptor } from 'src/app/core/models/grid/grid-descriptor';
import { OilsService } from './services/oils.service';
import { Oils } from '../../core/models/oils';
import { ModalDescriptor } from '../../core/models/modal/modal-descriptor';
import { Observable } from 'rxjs';
import { keyframes } from '@angular/animations';
import { ToastService } from 'src/app/core/services/toast.service';

 export class OilVisc {
               public id: number;
               public id_country: number;
               public oilName: string;
               public priorityOrder: number;
}
@Component({
  selector: 'app-oils',
  templateUrl: './oils.component.html',
  styleUrls: ['./oils.component.scss']
})
export class OilsComponent implements OnInit {

  public gridDescriptor: GridDescriptor;
  public isModifOK = false;

  public selectedCountry: Country;
  public selectedOils: Oils;
  public countries: Array<Country> = [];

  public oils = [];

  // public originalOilList: Oils;
  originalOilList: Oils;
   public lineStructure: Oils = {
    id: undefined,
    id_country: undefined,
    oilName: '',
    priorityOrder: undefined
   };


  private modalDescriptorModel: ModalDescriptor = {
    width: 450,
    height: 200,
    title: '',
    titleParameters: {},
    message: '',
    messageParameters: {},
    showModal: false,
    actions: [],
    clickOutsideBehavior: undefined,
    clickOutsideBehaviorParameters: []
  };

  public modalDescriptor: ModalDescriptor = JSON.parse(JSON.stringify(this.modalDescriptorModel));
  totalItem: number;
  lastPage: number;
  flagDown: boolean;
  flagUp: boolean;

  constructor(private countriesService: CountriesService,
    private toastService: ToastService,
    private oilsService: OilsService) { }
  // ngOnDestroy(): void {
  //   throw new Error('Method not implemented.');
  // }

  ngOnInit() {
    this.getCountries();
  }
  getCountries(searchText?: string) {
    this.countriesService.getCountries(searchText).subscribe(
      (result: { total: number, countries: Array<Country>, oils: Array<Oils> }) => {
        if (result.countries && result.countries.length > 0) {
            this.setCountries(result.countries, result.oils);
        }
      }
    );
  }

  setCountries(countries: Array<Country>,  oils: Array<Oils> ) {
    this.countries = countries;
    this.oils = oils;
    this.setDescriptor();
    if (this.countriesService.countryId) {
      this.selectedCountry = this.countries.find((country: Country) => country.id === this.countriesService.countryId);
      if (this.selectedCountry) {
        this.oilsService.getOilByCountry(this.selectedCountry, this.lineStructure)
          .subscribe(
            data => {
              this.originalOilList = data;
              console.log('DATA(set)===== ' + JSON.stringify(data));
              this.oils = this.createOilsArrayFromObject(data);
               console.log('set   DATA(onCountryChange)=====       ' + JSON.stringify(data));
            }
          );
      }
    }
  }

  onCountryChange(country: Country, oils: Oils) {
    // this.oils = [];
    this.selectedCountry = country;
    this.selectedOils = oils;
    // console.log('COUNTRY==      ' + this.selectedCountry);
    // console.log('OILS==      ' + this.selectedOils);
    this.countriesService.setCountry(country.id);
    this.oilsService.getOilByCountry(this.selectedCountry, this.selectedOils)
      .subscribe(
        data => {
          this.originalOilList = data;
              // console.log('DATA(onCountryChange)=====       ' + JSON.stringify(data));
          this.oils = this.createOilsArrayFromObject(this.originalOilList);
          // console.log('THIS.OILS===   ' + JSON.stringify(this.oils));

        }
      );
  }

  createOilsArrayFromObject(data: any) {
    let sortedOilArray = [];
      // console.log('DATA==          ' + JSON.stringify(data));
      let i = 0;
    // tslint:disable-next-line:forin
    for (const key in data) {
        // tslint:disable-next-line:max-line-length
        sortedOilArray.push({ id: data[i].id, oilName: data[i].oilName, priorityOrder: data[i].priorityOrder, id_country : this.selectedCountry.id });
       const mp = [];
       let j = 0;
       mp[j++] = data[i].id;
        i++;
    }
    sortedOilArray = sortedOilArray.sort((a, b) => a.priorityOrder - b.priorityOrder);
    return sortedOilArray;
  }

  setDescriptor() {
    this.gridDescriptor = {
      columns: [
        {
          id: 2,
          label: 'APP.OILS.PRIORITY',
          labelParameters: {},
          value: 'priorityOrder',
          type: 'number',
          required: true,
          weight: '1'
        },
        {
          id: 3,
          label: 'APP.CONFIGURATION.OILS',
          labelParameters: {},
          value: 'oilName',
          type: 'text',
          required: true,
          weight: '1'
        }
      ],
      actions: [
        {
          label: 'up',
          tooltip: '',
          icon: 'fas fa-arrow-up hiddable-first',
          behavior: this.changeArrayOrderUp.bind(this),
          confirmation: true,
          parameters: []
        },
        {
          label: 'down',
          tooltip: '',
          icon: 'fas fa-arrow-down hiddable-last',
          behavior: this.changeArrayOrderDown.bind(this),
          confirmation: true,
          parameters: []
        },
         {
          label: 'remove',
          tooltip: 'APP.RULES.REMOVE_LINE_TOOLTIP',
          icon: 'fa-trash error-button',
          behavior: this.removeOils.bind(this),
          confirmation: true,
          parameters: []
        }
      ],
      actionWeight: '1',
      newLine: true
    };
  }


  changeArrayOrderDown(id: any, index: number) {
    this.oils[index].priorityOrder = this.oils[index].priorityOrder + 1;
    this.oils[index + 1].priorityOrder = this.oils[index + 1].priorityOrder - 1;
    this.oils = this.oils.sort((a, b) => a.priorityOrder - b.priorityOrder);
    this.saveNeworder(this.oils);
  }

  changeArrayOrderUp(id: any, index: number) {
    this.oils[index].priorityOrder = this.oils[index].priorityOrder - 1;
    this.oils[index - 1].priorityOrder = this.oils[index - 1].priorityOrder + 1;
    this.oils = this.oils.sort((a, b) => a.priorityOrder - b.priorityOrder);

    this.saveNeworder(this.oils);
  }


  saveNeworder(o: Array<OilVisc>) {
    console.log(`OilVisc` + JSON.stringify(o));
    this.oilsService.updateOilList(o)
      .subscribe(
        result => {
          this.createOilsArrayFromObject(o);
          console.log(`RESULT`);
        },
        error => {
          this.createOilsArrayFromObject(o);
          console.log(`ERRORRR  ` + JSON.stringify(this.createOilsArrayFromObject(o)));
        }
      );
    // this.oilsService.updateOilList(o);
    // this.createOilsArrayFromObject(o);
      // this.oilsService.getOilByCountry(this.selectedCountry, this.selectedOils)
      // .subscribe(
      //   dx => {
      //     this.originalOilList = dx;
      //         // console.log('DATA(onCountryChange)=====       ' + JSON.stringify(data));
      //     this.oils = this.createOilsArrayFromObject(this.originalOilList);
      //     // console.log('THIS.OILS===   ' + JSON.stringify(this.oils));
      //   }
      // );
  }


   addLine(newLine: {oilName: string, priorityOrder: number, id: number}, id: any) {
    console.log(`addline222` + JSON.stringify(newLine));
    if (newLine.id) {
      this.oilsService.setLine(this.selectedCountry, newLine).subscribe(
        (oils: Oils) => {
          const index = this.oils.findIndex((d) => d.id === oils.id);
          if (index > -1) {
            this.oils[index] = oils;
          } else {
            this.oils.push(oils);
          }
          this.gridDescriptor.newLine = true; // del
          this.isModifOK = !this.isModifOK; // save
        }
      );
    } else {
      // const objects = this.oils;
      // const priorityOrders  = objects.map(obj => obj.priorityOrder);
      // const oilnames = objects.map(obj => obj.oilName);

    // this.oils = this.oils.sort((a, b) => a.priorityOrder - b.priorityOrder);
    let isError = false;
    // tslint:disable-next-line:forin
    for ( const d in this.oils) {
      // tslint:disable-next-line:triple-equals
      if (newLine.priorityOrder == this.originalOilList[d].priorityOrder) {
        isError = true;
        break;
      }
      if (newLine.oilName === this.originalOilList[d].oilName) {
        isError = true;
        break;
      }
    }
      // if (newLine.priorityOrder in priorityOrders || newLine.oilName in oilnames) {
        if (isError) {
      this.toastService.setToast('APP.DISCOUNT_GRID.ERRORS.NOD', 'error')
      } else {
        this.oilsService.addLine(this.selectedCountry, newLine).subscribe(
        (newOils: Oils) => {
          this.oils.push(newOils);
          this.gridDescriptor.newLine = true;
          this.isModifOK = !this.isModifOK;
          this.onCountryChange(this.selectedCountry, newOils);
        }
      );
      }
    }
  }

   closeModal() {
    this.modalDescriptor = JSON.parse(JSON.stringify(this.modalDescriptorModel));
  }

  confirmModal(observer) {
    observer.next(true);
    observer.complete();
    this.closeModal();
  }

  cancelModal(observer) {
    observer.next(false);
    observer.complete();
    this.closeModal();
  }

  openRemoveModal(oils: Oils) {
    return new Observable((observer) => {
      this.modalDescriptor.title = 'APP.PRESTATIONS_GRID.REMOVE_MODAL_TITLE';
      this.modalDescriptor.message = 'APP.PRESTATIONS_GRID.REMOVE_MODAL_MESSAGE';
      this.modalDescriptor.actions.push({
        icon: '',
        label: 'APP.ACTIONS.CANCEL',
        color: 'white',
        behavior: this.cancelModal.bind(this),
        behaviorParameters: [observer]
      });
      this.modalDescriptor.actions.push({
        icon: '',
        label: 'APP.ACTIONS.CONFIRM',
        color: 'red',
        behavior: this.confirmModal.bind(this),
        behaviorParameters: [observer]
      });
      this.modalDescriptor.clickOutsideBehavior = this.cancelModal.bind(this);
      this.modalDescriptor.clickOutsideBehaviorParameters = [observer];
      this.modalDescriptor.showModal = true;
    });
  }
  setOils(oils: Array<Oils>) {
    this.oils = oils;
    console.log(`setOils` + oils);
  }

   removeOils(id: any, idx: number) {
            // console.log('id ' + idx  );
      //         const mp = [];
      //  let j = 0;
      const x  = this.originalOilList[idx].id;
      // const y = this.originalOilList[idx].id;
            // console.log('oil ID ' + JSON.stringify(this.originalOilList[idx].id));
            console.log(`idx ` + x);
            // idx = JSON.stringify(this.originalOilList[idx].id);
    // const index = this.oils.findIndex((r) => r.y === id);
            // console.log('index ' + index  );
    // if (index > -1) {
      this.openRemoveModal(this.oils[x]).subscribe(
        (success: boolean) => {
          if (success) {
            this.oilsService.removeOils(x).subscribe(
              (data) => {
                // this.getOils(this.selectedCountry, 0);
                const newOils = this.oils;
                newOils.splice(x, 1);
                this.setOils(newOils);

                // this.createOilsArrayFromObject(this.originalOilList);
                this.oilsService.getOilByCountry(this.selectedCountry, this.selectedOils)
      .subscribe(
        dx => {
          this.originalOilList = dx;
              // console.log('DATA(onCountryChange)=====       ' + JSON.stringify(data));
          this.oils = this.createOilsArrayFromObject(this.originalOilList);
          // console.log('THIS.OILS===   ' + JSON.stringify(this.oils));
        }
      );
              }
            );
          }
        }
      );
    // }
  }
}
