import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from './utils.service';
import { map } from 'rxjs/operators';
import { Country } from '../models/country';

const COUNTRY_PATH = '/countries';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  public countryId: number;

  constructor(private http: HttpClient, private utilsService: UtilsService) {}

  setCountry(countryId: number) {
    this.countryId = countryId;
  }

  getCountries(search?: string, page?: number) {
    return this.http.get(`${environment.baseUrl}${COUNTRY_PATH}${this.utilsService.buildQueryParams({search, page})}`).pipe(
      map((data: any) => {
        const countries: Array<Country> = [];
        if (data.objects && data.objects.length > 0) {
          data.objects.forEach((country) => {
            countries.push(Country.mapToCountry(country));
          });
        }
        return {total: data.totalCount, countries};
      })
    );
  }

  getCountry(countryId: number) {
    return this.http.get(`${environment.baseUrl}${COUNTRY_PATH}/${countryId}`).pipe(
      map((data: any) => {
        return Country.mapToCountry(data);
      })
    );
  }

  addLine(newLine: Country) {
    newLine.code = newLine.code.toUpperCase();
    return this.http.post(`${environment.baseUrl}${COUNTRY_PATH}`, newLine);
  }

  setLine(newLine: Country) {
    newLine.code = newLine.code.toUpperCase();
    return this.http.put(`${environment.baseUrl}${COUNTRY_PATH}`, newLine);
  }

  removeCountry(countryId: number) {
    return this.http.delete(`${environment.baseUrl}${COUNTRY_PATH}/${countryId}`);
  }
}
