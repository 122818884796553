import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {

  // --------------------------------------------------
  //                     PROPERTIES
  // --------------------------------------------------

  @Input() checked: boolean;
  @Input() disabled: boolean;

  @Output() checkboxCheckedChange = new EventEmitter<any>();


  // --------------------------------------------------
  //                     CONSTRUCTOR
  // --------------------------------------------------

  constructor() {}


  // --------------------------------------------------
  //                     METHODS
  // --------------------------------------------------

  /**
   * Component initialization
   */
  ngOnInit() {}

  /**
   * Check the checkbox
   */
  checkCheckbox() {
    if (!this.disabled) {
      this.checkboxCheckedChange.emit(!this.checked);
    }
  }
}
